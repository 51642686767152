export class SalidaDetalle {
  ID: number;
  Cantidad: number;
  CostoProm: number;
  Importe: number;
  ID_Producto: number;
  ID_Salida: number;
  constructor() {
    this.Cantidad = 0;
    this.Importe = 0;
  }
}
