export class FisicoDetalle {
  ID: number;
  CostoPromedio: number;
  CantidadExistente: number;
  CantidadTeorico: number;
  Diferencia: number;
  CostoDiferencia: number;
  Status: string;
  ID_Producto: number;
  ID_Fisico: number;
  constructor() {
    this.CostoPromedio = 0;
    this.CantidadExistente = 0;
    this.CantidadTeorico = 0;
    this.Diferencia = 0;
    this.CostoDiferencia = 0;
    this.Status = 'A';
  }
}
