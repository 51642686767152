export class EntradaDetalle {
  ID: number;
  Cantidad: number;
  CostoUnitario: number;
  Descuento: number;
  Importe: number;
  ID_Proveedor: number;
  ID_Producto: number;
  ID_Entrada: number;
  constructor() {
    this.Cantidad = 0;
    this.Descuento = 0;
    this.Importe = 0;
  }
}
