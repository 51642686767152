import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SucursalesComponent } from './sucursales/sucursales.component';
import { AlmacenesComponent } from './almacenes/almacenes.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ModelosComponent } from './modelos/modelos.component';
import { ProductosComponent } from './productos/productos.component';
import { ProveedoresComponent } from './proveedores/proveedores.component';
import { TiposEntradaSalidaComponent } from './tipos-entrada-salida/tipos-entrada-salida.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { TiposUsuarioComponent } from './tipos-usuario/tipos-usuario.component';
import { CotizacionesComponent } from './cotizaciones/cotizaciones.component';
import { VentasComponent } from './ventas/ventas.component';
import { ComprasComponent } from './compras/compras.component';
import { OrdenComprasComponent } from './orden-compras/orden-compras.component';
import { SolicitudAlmacenComponent } from './solicitud-almacen/solicitud-almacen.component';
import { ComodatosComponent } from './comodatos/comodatos.component';
import { ComodatoMultipleComponent } from './comodato-multiple/comodato-multiple.component';
import { RetirosComponent } from './retiros/retiros.component';
import { RemisionVentasComponent } from './remision-ventas/remision-ventas.component';
import { TraspasosComponent } from './traspasos/traspasos.component';
import { RecepcionTraspasosComponent } from './recepcion-traspasos/recepcion-traspasos.component';
import { EntradasComponent } from './entradas/entradas.component';
import { SalidasComponent } from './salidas/salidas.component';
import { TiposESComponent } from './tipos-es/tipos-es.component';
import { InventarioFisicoComponent } from './inventario-fisico/inventario-fisico.component';
import { GeneracionProductokitComponent } from './generacion-productokit/generacion-productokit.component';
import { DescomposicionProductoComponent } from './descomposicion-producto/descomposicion-producto.component';
import { MergeProductoComponent } from './merge-producto/merge-producto.component';
import { ExistenciasComponent } from './existencias/existencias.component';
import { LineasProductoComponent } from './lineas-producto/lineas-producto.component';
import { SublineasProductoComponent } from './sublineas-producto/sublineas-producto.component';
import { UnidadMedidaComponent } from './unidad-medida/unidad-medida.component';
import { LineasProveedorComponent } from './lineas-proveedor/lineas-proveedor.component';
import { ReportesComponent } from './reportes/reportes.component';

const routes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },
  {
    path: 'sucursales',
    component: SucursalesComponent
  },
  {
    path: 'almacenes',
    component: AlmacenesComponent
  },
  {
    path: 'clientes',
    component: ClientesComponent
  },
  {
    path: 'modelos',
    component: ModelosComponent
  },
  {
    path: 'productos',
    component: ProductosComponent
  },
  {
    path: 'proveedores',
    component: ProveedoresComponent
  },
  {
    path: 'tiposentradasalida',
    component: TiposEntradaSalidaComponent
  },
  {
    path: 'usuarios',
    component: UsuariosComponent
  },
  {
    path: 'tiposusuario',
    component: TiposUsuarioComponent
  },
  {
    path: 'cotizaciones',
    component: CotizacionesComponent
  },
  {
    path: 'ventas',
    component: VentasComponent
  },
  {
    path: 'compras',
    component: ComprasComponent
  },
  {
    path: 'ordencompra',
    component: OrdenComprasComponent
  },
  {
    path: 'solicitudalmacen',
    component: SolicitudAlmacenComponent
  },
  {
    path: 'comodatos',
    component: ComodatosComponent
  },
  {
    path: 'comodatomulti',
    component: ComodatoMultipleComponent
  },
  {
    path: 'retiros',
    component: RetirosComponent
  },
  {
    path: 'remisionventas',
    component: RemisionVentasComponent
  },
  {
    path: 'traspasos',
    component: TraspasosComponent
  },
  {
    path: 'recepciontraspaso',
    component: RecepcionTraspasosComponent
  },
  {
    path: 'entradas',
    component: EntradasComponent
  },
  {
    path: 'salidas',
    component: SalidasComponent
  },
  {
    path: 'tiposes',
    component: TiposESComponent
  },
  {
    path: 'inventariofisico',
    component: InventarioFisicoComponent
  },
  {
    path: 'generarkit',
    component: GeneracionProductokitComponent
  },
  {
    path: 'descproducto',
    component: DescomposicionProductoComponent
  },
  {
    path: 'mergeproducto',
    component: MergeProductoComponent
  },
  {
    path: 'existencias',
    component: ExistenciasComponent
  },
  {
    path: 'lineaproducto',
    component: LineasProductoComponent
  },
  {
    path: 'sublineaproducto',
    component: SublineasProductoComponent
  },
  {
    path: 'unidadmedida',
    component: UnidadMedidaComponent
  },
  {
    path: 'lineaproveedor',
    component: LineasProveedorComponent
  },
  {
    path: 'reportes/:tipo',
    component: ReportesComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
