import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import {
  I18n,
  CustomDatepickerI18n,
} from "../../directives/custom-datepickerI18n";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users/users.service";
import { Cotizacion } from "../../models/Cotizacion";
import { CotizacionDetalle } from "../../models/CotizacionDetalle";
import { CotizacionesService } from "../../services/cotizaciones/cotizaciones.service";
import { SolicitudAlmacen } from "../../models/SolicitudAlmacen";
import { SolicitudAlmacenDetalle } from "../../models/SolicitudAlmacenDetalle";
import { AclService } from "ng2-acl/dist";

@Component({
  selector: "app-cotizaciones",
  templateUrl: "./cotizaciones.component.html",
  styleUrls: ["./cotizaciones.component.css"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class CotizacionesComponent implements OnInit {
  @ViewChild("editModal") editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalSolRef: BsModalRef;
  modaldetallesolRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  lista: any[] = [];
  model: Cotizacion = new Cotizacion();
  modeldetalle: CotizacionDetalle = new CotizacionDetalle();
  detalles: CotizacionDetalle[] = [];
  iva: number;
  total: number;
  radioProducto: any;
  activeNormal: any;
  activeModelo: any;
  activeKit: any;
  extendido = false;
  radiodescripcion: any;
  existencia: number;
  corto: string;
  licitacion: string;
  showguardar = true;

  from: NgbDateStruct;
  to: NgbDateStruct;
  vendedor: any;
  cliente: any;
  estatusval: any;
  vendedores: any[] = [];
  clientes: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  productos: any[] = [];
  productoskit: any[] = [];
  modelos: any[] = [];
  estatus = [
    { ID: -1, Nombre: "TODOS", Status: "" },
    { ID: 1, Nombre: "Aceptada", Status: "A" },
    { ID: 2, Nombre: "Vigente", Status: "V" },
    { ID: 3, Nombre: "Expirada", Status: "E" },
  ];

  // Solicitud
  modelSol: SolicitudAlmacen = new SolicitudAlmacen();
  modeldetallesol: SolicitudAlmacenDetalle = new SolicitudAlmacenDetalle();
  detallesSolicitud: SolicitudAlmacenDetalle[] = [];
  productosModelo: any[] = [];

  isEditCot = false;
  currentIndexDetailCot = -1;

  isEditSol = false;
  currentIndexDetailSol = -1;

  constructor(
    private _userService: UsersService,
    private _cotizacionService: CotizacionesService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public aclService: AclService
  ) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    // this.from = {
    //   day: new Date().getDate(),
    //   month: new Date().getMonth() + 1,
    //   year: new Date().getFullYear()
    // };
    // this.to = {
    //   day: new Date().getDate(),
    //   month: new Date().getMonth() + 1,
    //   year: new Date().getFullYear()
    // };
    this.estatusval = "";
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = "";
    let to = "";
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._cotizacionService
      .getLista(from, to, this.cliente, this.vendedor, this.estatusval)
      .subscribe(
        (data: any) => {
          this.lista = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      this.model.ID_UsuarioModificador = this._userService.user.ID;
      if (
        this.model.Status === undefined ||
        this.model.Status === null ||
        this.model.Status === ""
      ) {
        this.model.Status = "V";
      }

      const model = {
        model: this.model,
        detalles: this.detalles,
      };
      this._cotizacionService.guardar(model).subscribe(
        (success) => {
          this.toastr.success("Cotización guardado con exito.", "Guardado!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.onBuscar();
          FormData.resetForm();
          this.modalRef.hide();
        },
        (error) => {
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          });
        }
      );
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Cotizacion();
    this.detalles = [];
    this.showguardar = true;
    this.isEditCot = false;
    this.currentIndexDetailCot = -1;
    this.isEditSol = false;
    this.currentIndexDetailSol = -1;
    if (id <= 0) {
      this.model.Pie =
        "ESTOS PRECIOS SON MAS EL 16% DE IVA LOS PRECIOS QUE MARCA NUESTRA COTIZACION LOS SOSTENDREMOS POR UN PERIODO DE 10 DIAS, YA QUE ESTAMOS EXPUESTOS A QUE NUESTROS PROVEEDORES CAMBIEN DE PRECIOS. SIN OTRO PARTICULAR POR EL MOMENTO, Y EN ESPERA DE PODER CONTAR CON SU PREFERENCIA QUEDAMOS A SUS APRECIABLES ORDENES.";
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._cotizacionService.getCotizacion(id).subscribe(
        (data) => {
          this.model = data.model;
          this.detalles = data.detalles;
          if (this.model.Fecha) {
            this.model.Fecha = new Date(this.model.Fecha);
            this.model.SelectedDate = this.getDateStructFromDate(
              this.model.Fecha
            );
          }
          if (this.model.Status !== "V") {
            this.showguardar = false;
          }

          this.modalRef = this.modalService.show(template, this.configLarge);
        },
        (error) =>
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          })
      );
    }
  }

  getCombos() {
    this._cotizacionService.getCombos().subscribe(
      (data) => {
        this.vendedores = data.vendedores;
        this.clientes = data.clientes;
        this.productos = data.productos;
        this.productoskit = data.productoskit;
        this.modelos = data.modelos;
        this.sucursales = data.sucursales;
        this.almacenes = data.almacenes;
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onShowDetalle(
    model: CotizacionDetalle,
    currentIndexDetailRem: number,
    template: TemplateRef<any>
  ) {
    this.modeldetalle = new CotizacionDetalle();
    this.isEditCot = false;
    this.currentIndexDetailCot = -1;
    if (model) {
      this.modeldetalle = <CotizacionDetalle>JSON.parse(JSON.stringify(model));
      this.currentIndexDetailCot = currentIndexDetailRem;
      this.isEditCot = true;

      if (model.ID_Producto && model.esKit === "N") {
        this.radioProducto = 1;
      } else if (model.ID_Producto && model.esKit === "Y") {
        this.radioProducto = 3;
      } else {
        this.radioProducto = 2;
      }

      this.onRadioProdChanged();
    } else {
      this.radioProducto = 2;
      this.onRadioProdChanged();
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteDetalle(model: CotizacionDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex(
          (Item: CotizacionDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onGetClave(model: CotizacionDetalle) {
    if (model.ID_Producto && model.esKit === "N") {
      const producto = this.productos.filter(
        (item) => item.ID === model.ID_Producto
      )[0];
      return producto.Codigo;
    } else if (model.ID_Producto && model.esKit === "Y") {
      const producto = this.productoskit.filter(
        (item) => item.ID === model.ID_Producto
      )[0];
      return producto.Codigo;
    } else {
      const modelo = this.modelos.filter(
        (item) => item.ID === model.ID_ModeloProducto
      )[0];
      return modelo.Clave;
    }
  }

  onGetSubTotal() {
    let subtotal = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.PrecioUnitario;
    }

    this.iva = subtotal * 0.16;
    this.total = subtotal + this.iva;
    this.model.Subtotal = subtotal;
    this.model.Total = this.total;

    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Subtotal =
      this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.isEditCot == true) {
        this.detalles[this.currentIndexDetailCot] = <CotizacionDetalle>(
          JSON.parse(JSON.stringify(this.modeldetalle))
        );

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        this.detalles.push(this.modeldetalle);
        this.toastr.success("Producto agregado con exito.", "Agregado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      }
    }
  }

  onRadioProdChanged() {
    if (this.radioProducto === 1) {
      this.activeNormal = true;
      this.activeModelo = false;
      this.activeKit = false;
      this.modeldetalle.esKit = "N";
    } else if (this.radioProducto === 2) {
      this.activeNormal = false;
      this.activeModelo = true;
      this.activeKit = false;
      this.existencia = 0;
      this.modeldetalle.esKit = "N";
    } else {
      this.activeNormal = false;
      this.activeModelo = false;
      this.activeKit = true;
      this.modeldetalle.esKit = "Y";
    }
  }

  onRadioChanged() {
    if (this.radiodescripcion === 1) {
      this.modeldetalle.InfoProducto = this.corto;
    } else {
      this.modeldetalle.InfoProducto = this.licitacion;
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.existencia = selectedValue.Cantidad;
    this.modeldetalle.ID_ModeloProducto = null;
  }

  onModeloChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.corto = selectedValue.Nombre;
    this.licitacion = selectedValue.Extension;
    this.modeldetalle.ID_Producto = null;
  }

  onProductoKitChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.existencia = selectedValue.Cantidad;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
      item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.Codigo + " - " + item.Nombre).toLocaleLowerCase().indexOf(term) > -1
    );
  }

  onFechaChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id,
    };
    this._cotizacionService.imprimir(model).subscribe(
      (data: any) => {
        const fileblob = new Blob([data], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, "CoverSheet.pdf");
        } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        });
      }
    );
  }

  // Solicitud de Almacen
  onShowGenerar(
    id: number,
    template: TemplateRef<any>,
    templateModelo: TemplateRef<any>
  ) {
    this.modelSol = new SolicitudAlmacen();
    this.detallesSolicitud = [];
    this.productosModelo = [];
    this.isEditSol = false;
    this.currentIndexDetailSol = -1;

    this._cotizacionService.getGenerarSolicitudCotizacion(id).subscribe(
      (data) => {
        this.modelSol = data.modelSolicitud;
        this.detallesSolicitud = data.detallesSolicitud;
        this.productosModelo = data.productosModelo;
        if (this.model.Fecha) {
          this.modelSol.Fecha = new Date(this.modelSol.Fecha);
          this.modelSol.SelectedDate = this.getDateStructFromDate(
            this.modelSol.Fecha
          );
        }

        if (this.modelSol.CompEntrega) {
          this.modelSol.CompEntrega = new Date(this.modelSol.CompEntrega);
          this.modelSol.SelectedEntregaDate = this.getDateStructFromDate(
            this.modelSol.CompEntrega
          );
        }

        this.modalSolRef = this.modalService.show(template, this.configLarge);

        if (this.productosModelo.length > 0) {
          this.modaldetallesolRef = this.modalService.show(
            templateModelo,
            this.config
          );
        }
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.modelSol.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  onFechaEntrChanged(selectedDate: NgbDateStruct) {
    this.modelSol.CompEntrega = this.getDateFromDateStruct(selectedDate);
  }

  onShowSolDetalle(
    model: SolicitudAlmacenDetalle,
    currentIndexDetail: number,
    template: TemplateRef<any>
  ) {
    this.modeldetallesol = new SolicitudAlmacenDetalle();
    this.isEditSol = false;
    this.currentIndexDetailSol = -1;
    if (model) {
      this.modeldetallesol = <SolicitudAlmacenDetalle>(
        JSON.parse(JSON.stringify(model))
      );
      this.currentIndexDetailSol = currentIndexDetail;
      this.isEditSol = true;

      if (model.ID_Producto && model.esKit === "N") {
        this.radioProducto = 1;
      } else if (model.ID_Producto && model.esKit === "Y") {
        this.radioProducto = 3;
      } else {
        this.radioProducto = 2;
      }

      this.onRadioProdChanged();
    } else {
      this.radioProducto = 2;
      this.onRadioProdChanged();
    }
    this.modaldetallesolRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: SolicitudAlmacenDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detallesSolicitud.findIndex(
          (Item: SolicitudAlmacenDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onSubmitSol(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que quiere guardar Solicitud de Almacen.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.modelSol.ID_UltimoUsuario = this._userService.user.ID;
          if (
            this.modelSol.Status === undefined ||
            this.modelSol.Status === null ||
            this.modelSol.Status === ""
          ) {
            this.modelSol.Status = "M";
          }

          if (
            this.modelSol.ID_UsuarioRegistro === undefined ||
            this.modelSol.ID_UsuarioRegistro == null ||
            this.modelSol.ID_UsuarioRegistro == 0
          ) {
            this.modelSol.ID_UsuarioRegistro = this._userService.user.ID;
          }

          const model = {
            model: this.modelSol,
            detalles: this.detallesSolicitud,
          };
          this._cotizacionService.guardarSolicitud(model).subscribe(
            (success) => {
              this.toastr.success(
                "Solicitud de Almacen guardado con exito.",
                "Guardado!",
                {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "increasing",
                }
              );
              this.onBuscar();
              FormData.resetForm();
              this.modalSolRef.hide();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      });
    }
  }

  onSubmitDetalleSolicitud(FormData: any) {
    if (FormData.valid) {
      if (this.isEditSol == true) {
        this.modeldetallesol.CantidadSurtir = this.modeldetallesol.Cantidad;
        this.detallesSolicitud[this.currentIndexDetailSol] = <
          SolicitudAlmacenDetalle
        >JSON.parse(JSON.stringify(this.modeldetallesol));

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        this.modeldetallesol.CantidadSurtir = this.modeldetallesol.Cantidad;
        this.detallesSolicitud.push(this.modeldetallesol);
        this.toastr.success("Producto agregado con exito.", "Agregado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      }
    }
  }

  onSubmitModelosSolicitud(FormData: any) {
    this.productosModelo.forEach((modelo) => {
      let modelDetalle = new SolicitudAlmacenDetalle();

      const producto = modelo.Productos.filter(
        (item) => item.ID === modelo.ID_Producto
      )[0];

      modelDetalle.NoRenglon = 0;
      modelDetalle.Cantidad = modelo.Cantidad;
      modelDetalle.InfoProducto = producto.Nombre;
      modelDetalle.ID_Producto = modelo.ID_Producto;
      modelDetalle.ID_ModeloProducto = modelo.ID_ModeloProducto;
      modelDetalle.esKit = modelo.esKit;
      modelDetalle.Status = "A";
      modelDetalle.ExistenciaActual = producto.Cantidad;
      modelDetalle.CantidadSurtir = modelo.Cantidad;

      this.detallesSolicitud.push(modelDetalle);
    });

    this.toastr.success("Productos agregados con exito.", "Guardado!", {
      timeOut: 10000,
      progressBar: true,
      progressAnimation: "increasing",
    });
    FormData.resetForm();
    this.modaldetallesolRef.hide();
  }
}
