export class Producto {
  ID: number;
  Codigo: string;
  Nombre: string;
  Cantidad: number;
  Costo: number;
  Precio: number;
  CodigoProveedor: string;
  UltimoMov: any;
  PuntoReorden: number;
  Minimo: number;
  Maximo: number;
  RutaImagen: string;
  Inventariable: string;
  Descuento: number;
  Status: string;
  ID_SubLinea?: number;
  ID_Unidad?: number;
  ID_Proveedor?: number;
  UltimoCosto: number;
  FechaUltimoCosto?: any;
  esKit: string;
  Extension1: string;
  Extension2: string;
  ID_ModeloProducto?: number;
  esSerial?: string;
  esSerialprd?: string;
  CodigoSAT: string;
  Activo: boolean;
}
