import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Remision {
  ID: number;
  Fecha: any;
  Descripcion: string;
  FolioSucursal: string;
  SerieFactura: string;
  FolioFactura: string;
  FormaPago: string;
  Observaciones: string;
  RFC: string;
  Notas: string;
  Domicilio: string;
  Ciudad: string;
  Subtotal: number;
  Descuento: number;
  Impuestos: number;
  Total: number;
  Status: string;
  ID_Almacen: number;
  ID_Vendedor: number;
  ID_Cliente: number;
  ID_DireccionCliente?: number;
  Atencion: string;
  Solicitud: string;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Observaciones = '';
    this.Descripcion = '';
    this.SerieFactura = '';
    this.FolioFactura = '';
    this.Fecha = new Date();
    this.Status = 'B';
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
