import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { AppConfig } from '../appConfig/app.config';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  // private _url = `${AppSettings.API_ENDPOINT}/Ventas`;
  private _url = `${AppConfig.settings.API_ENDPOINT}/Ventas`;
  private _getLista = `${this._url}/Lista`;
  private _getGetVenta = `${this._url}/GetVenta`;
  private _guardar = `${this._url}/Guardar`;
  private _procesar = `${this._url}/Procesar`;
  private _cancelar = `${this._url}/Cancelar`;
  private _eliminar = `${this._url}/Eliminar`;
  private _getDireccionDefault = `${this._url}/DireccionDefault`;
  private _getDirecciones = `${this._url}/Direcciones`;
  private _getGetProductosKit = `${this._url}/GetProductosKit`;
  private _getCombos = `${this._url}/ListasCombos`;
  private _getImprimir = `${this._url}/Imprimir`;


  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, sucursal: number, almacen: number, cliente: number, vendedor: number, folioSucursal: String, factura: String, folio: String): Observable<any[]> {
    const params = new HttpParams()
      .set('from', from.toString())
      .set('to', to.toString())
      .set('sucursal', String(sucursal))
      .set('almacen', String(almacen))
      .set('cliente', String(cliente))
      .set('vendedor', String(vendedor))
      .set('folioSucursal', String(folioSucursal))
      .set('factura', String(factura))
      .set('folio', String(folio));

    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getVenta(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetVenta}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  procesar(model: any): Observable<any> {
    return this._http.post<any>(`${this._procesar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  cancelar(model: any): Observable<any> {
    return this._http.post<any>(`${this._cancelar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  eliminar(model: any): Observable<any> {
    return this._http.post<any>(`${this._eliminar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getDireccionDefault(id: number): Observable<any>  {
    return this._http.get<any[]>(`${this._getDireccionDefault}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getDirecciones(id: number): Observable<any>  {
    return this._http.get<any[]>(`${this._getDirecciones}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getProductos(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetProductosKit}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  imprimir(model) {
    return  this._http.post(`${this._getImprimir}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
