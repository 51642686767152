import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Fisico {
  ID: number;
  Fecha: any;
  Observaciones: string;
  Status: string;
  ID_Almacen: number;
  ID_Usuario: number;

  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Observaciones = '';
    this.Fecha = new Date();
    this.Status = 'G';
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
