import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { ComodatosService } from '../../services/comodatos/comodatos.service';
import { Direccionc } from 'src/app/models/Direccion';
import { Router } from '@angular/router';
import { RemisionDto } from '../../models/RemisionDto';
import { RemisionDetalleDto } from '../../models/RemisionDetalleDto';
import { Venta } from 'src/app/models/Venta';
import { VentaDetalle } from 'src/app/models/VentaDetalle';
import { AclService } from 'ng2-acl/dist';
import { RetirosService } from '../../services/retiros/retiros.service';
import { VentasService } from '../../services/ventas/ventas.service';

@Component({
  selector: 'app-comodato-multiple',
  templateUrl: './comodato-multiple.component.html',
  styleUrls: ['./comodato-multiple.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ComodatoMultipleComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  modalDevRef: BsModalRef;
  modalDevRetProcRef: BsModalRef;

  @ViewChild('templateVenta') templateVenta;
  @ViewChild('templateDevolucion') templateDevolucion;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'Custom-size-modal'
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-md'
  };

  pageVentaActual: number = 1; 
  pageRetiroActual: number = 1; 

  cliente: any;
  remisiones: RemisionDto[] = [];
  clientes: any[] = [];

  totalVentas = 0;
  totalDevoluciones = 0;

  // Ventas
  model: Venta = new Venta();
  modeldetalle: VentaDetalle = new VentaDetalle();
  detalles: VentaDetalle[] = [];
  clienteDireccion: Direccionc = new Direccionc();
  clienteDirecciones: Direccionc[] = [];

  productos: any[] = [];
  vendedores: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  metodospago: any[] = [];
  cfdis: any[] = [];
  iva: number;
  total: number;
  descuento: number;
  StatusList = [{ ID: 1, Descripcion: 'Contado' }, { ID: 2, Descripcion: 'Credito' }, { ID: 3, Descripcion: 'Por Definir' }];

  // Devolucion
  modelDevolucion: RemisionDto = new RemisionDto();

  // Procesar Venta Retiro
  listVentas: any[] = [];
  listRetiros: any[] = [];

  constructor(private _userService: UsersService, private _comodatoService: ComodatosService, private _retiroService: RetirosService, private _ventaService: VentasService, private modalService: BsModalService, private toastr: ToastrService, public router: Router, public aclService: AclService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.getCombos();
  }

  onBuscar() {
    this._comodatoService.getRemisionesCliente(this.cliente).subscribe(
      (data: any) => {
        this.remisiones = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  getCombos() {
    this._comodatoService.getCombos()
      .subscribe(
        data => {
          this.clientes = data.clientes;
          this.vendedores = data.vendedores;
          this.productos = data.productos;
          this.sucursales = data.sucursales;
          this.almacenes = data.almacenes;
          this.cfdis = data.cfdis;
          this.metodospago = data.metodospago;
        },
        error => this.toastr.error(error.message, 'Error!', {
          timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
        }) );
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.onBuscar();
  }

  GetMaxMinVenta(row: RemisionDetalleDto) {
    console.log('entro GetMaxMinVenta');
    if (row.venta === undefined || row.venta === null) {
        if (row.Cantidad > 0) {
            row.mindevolucion = 0;
            row.maxdevolucion = row.Cantidad;
            row.disabledretiro = false;
            row.venta = null;
            this.toastr.warning('La cantidad minima Retiro: ' + row.mindevolucion + ', Cantidad maxima: ' + row.maxdevolucion, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else {
            row.mindevolucion = 0;
            row.maxdevolucion = row.Cantidad;
            row.disabledretiro = false;
            row.venta = null;
            this.toastr.warning('La cantidad minima Retiro: ' + row.mindevolucion + ', Cantidad maxima: ' + row.maxdevolucion, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        }
    } else {
        if (row.venta >= row.Cantidad) {
            row.mindevolucion = 0;
            row.maxdevolucion = 0;
            row.disabledretiro = true;
            row.venta = row.venta;
            this.toastr.warning('La cantidad minima Retiro: ' + row.mindevolucion + ', Cantidad maxima: ' + row.maxdevolucion, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else if (row.Cantidad > row.venta) {
            const max = row.Cantidad - row.venta;
            row.mindevolucion = 0;
            row.maxdevolucion = max;
            row.disabledretiro = false;
            row.venta = row.venta;
            this.toastr.warning('La cantidad minima Retiro: ' + row.mindevolucion + ', Cantidad maxima: ' + row.maxdevolucion, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else if (row.Cantidad === row.venta) {
            row.mindevolucion = 0;
            row.maxdevolucion = 0;
            row.disabledretiro = true;
            row.venta = row.venta;
            this.toastr.warning('La cantidad minima Retiro: ' + row.mindevolucion + ', Cantidad maxima: ' + row.maxdevolucion, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        }
    }
  }

  GetMaxMinDevolucion(row: RemisionDetalleDto) {
    console.log('entro GetMaxMinDevolucion');
    if (row.devolucion === undefined || row.devolucion === null) {
        if (row.Cantidad > 0) {
            row.minventa = 0;
            row.maxventa = row.Cantidad;
            row.disabledventa = false;
            row.devolucion = null;
            this.toastr.warning('La cantidad minima Venta: ' + row.minventa + ', Cantidad maxima: ' + row.maxventa, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else {
            row.minventa = 0;
            row.maxventa = row.Cantidad;
            row.disabledventa = false;
            row.devolucion = null;
            this.toastr.warning('La cantidad minima Venta: ' + row.minventa + ', Cantidad maxima: ' + row.maxventa, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        }
    } else {
        if (row.devolucion >= row.Cantidad) {
            row.minventa = 0;
            row.maxventa = 0;
            row.disabledventa = true;
            row.devolucion = row.devolucion;
            this.toastr.warning('La cantidad minima Venta: ' + row.minventa + ', Cantidad maxima: ' + row.maxventa, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else if (row.Cantidad > row.devolucion) {
            const max = row.Cantidad - row.devolucion;
            row.minventa = 0;
            row.maxventa = max;
            row.disabledventa = false;
            row.devolucion = row.devolucion;
            this.toastr.warning('La cantidad minima Venta: ' + row.minventa + ', Cantidad maxima: ' + row.maxventa, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        } else if (row.Cantidad === row.devolucion) {
            row.minventa = 0;
            row.maxventa = 0;
            row.disabledventa = true;
            row.devolucion = row.devolucion;
            this.toastr.warning('La cantidad minima Venta: ' + row.minventa + ', Cantidad maxima: ' + row.maxventa, 'Cuidado!', {
              timeOut: 7000, progressBar: true, progressAnimation: 'increasing'
            });
        }
    }
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que desea guardar Comodatos?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then(async (result) => {
        if (result.value) {
          this.totalVentas = 0;
          this.totalDevoluciones = 0;
          this.remisiones.forEach(remision => {
            const ventas = remision.remisionDetalles.filter(
              item => item.venta > 0
            );

            const devolucion = remision.remisionDetalles.filter(
              item => item.devolucion > 0
            );

            ventas.forEach(ventaDetalle => {
              this.totalVentas += ventaDetalle.venta;
            });

            devolucion.forEach(devolucionDetalle => {
              this.totalDevoluciones += devolucionDetalle.devolucion;
            });
          });

          if (this.totalVentas > 0) {
            let ids = [];
            this.model = new Venta();
            this.detalles = [];

            this.model.Status = 'G';
            this.model.ID_Almacen = this.remisiones[0].ID_Almacen;
            this.model.ID_Vendedor = this.remisiones[0].ID_Vendedor;
            this.model.ID_Cliente = this.remisiones[0].ID_Cliente;

            const clitmp = this.clientes.filter(
              item => item.ID = this.model.ID_Cliente
            )[0];

            this._comodatoService.getDireccionDefault(this.model.ID_Cliente)
            .subscribe(
              data => {
                this.clienteDireccion = data;
                this.model.Domicilio = this.clienteDireccion.Direccion;
                this.model.Ciudad = clitmp.Ciudad;
                this.model.RFC = clitmp.Rfc;
                this.model.ID_DireccionCliente = this.clienteDireccion.ID;

              },
              error => this.toastr.error(error.message, 'Error!', {
                timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
              }) );
            this.remisiones.forEach(remision => {
              const ventas = remision.remisionDetalles.filter(
                item => item.venta > 0
              );

              if ( ventas.length > 0) {
                 ids.push(remision.ID);

                 ventas.forEach(ventaDetalle => {
                  let detalle = new VentaDetalle();
                  detalle.InfoProducto = ventaDetalle.InfoProducto;
                  detalle.Cantidad = ventaDetalle.venta;
                  detalle.PrecioUnitario = ventaDetalle.PrecioUnitario;
                  detalle.Subtotal = ventaDetalle.venta * ventaDetalle.PrecioUnitario;
                  detalle.Impuestos = 0;
                  detalle.Total = detalle.Subtotal;
                  detalle.ID_Venta = 0;
                  detalle.ID_Producto = ventaDetalle.ID_Producto;

                  this.detalles.push(detalle);

                });

              }

            });

            this.model.Descripcion = ids.join();

            this.modalRef = this.modalService.show(this.templateVenta, this.configLarge);
          } else if (this.totalDevoluciones > 0) {

            this.modelDevolucion = new RemisionDto();

            this.remisiones.forEach(remision => {
              const devolucion = remision.remisionDetalles.filter(
                item => item.devolucion > 0
              );
              devolucion.forEach(detalle => {
                this.modelDevolucion.remisionDetalles.push(detalle);
              });
            });

            this.modalDevRef = this.modalService.show(this.templateDevolucion, this.config);
          } else {
            this.router.navigate(['/comodatomulti']);
          }
        }
      });
    }
  }

  onCerrar() {
    this.router.navigate(['/dashboard']);
  }


  // Ventas
  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear()
    };

    return dateStruct;
  }

  onGetClave(model: VentaDetalle) {
    const producto = this.productos.filter(
      item => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  onGetSubTotal() {
    let subtotal = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.PrecioUnitario;
    }
    this.iva = (subtotal * 0.16);
    this.total = subtotal + this.iva;
    this.model.Subtotal = subtotal;
    this.model.Impuestos = this.iva;
    this.model.Total = this.total;

    return subtotal;
  }

  onCerrarVenta() {
    this.modalRef.hide();
  }

  onSubmitVenta(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Venta.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
              if (this.model.Status === undefined || this.model.Status === null || this.model.Status === '') {
                this.model.Status = 'G';
              }

              const model = {
                model: this.model,
                detalles: this.detalles,
                modelRemisiones: this.remisiones
              };
              this._comodatoService.guardarVenta(model)
            .subscribe(
              success => {
                  this.toastr.success('Venta guardado con exito.', 'Guardado!', {
                    timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
                  });
                  FormData.resetForm();
                  this.modalRef.hide();

                  // Devolucion
                  if (this.totalDevoluciones > 0) {
                    this.modelDevolucion = new RemisionDto();
                    this.remisiones.forEach(remision => {
                      const devolucion = remision.remisionDetalles.filter(
                        item => item.devolucion > 0
                      );
                      devolucion.forEach(detalle => {
                        this.modelDevolucion.remisionDetalles.push(detalle);
                      });
                    });

                    this.modalDevRef = this.modalService.show(this.templateDevolucion, this.config);
                  } else {
                    this.cliente = null;
                    this.remisiones = [];
                  }

              },
              error => {
                this.toastr.error(error.message, 'Error!', {
                  timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
                });
              });
        }
      });
    }
  }

  // Devolucion
  onFechaDevChanged(selectedDate: NgbDateStruct) {
    this.modelDevolucion.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  onSubmitDevolucion(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Esta seguro que quiere guardar Devolución.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!',
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false
      }).then((result) => {
        if (result.value) {
          this.remisiones.forEach(remision => {
            remision.Fecha = this.modelDevolucion.Fecha;
            remision.Observaciones = this.modelDevolucion.Observaciones;
          });
          const model = {
                model: this.remisiones
              };
          this._comodatoService.guardarDevolucion(model)
            .subscribe(
              success => {

                if ( success.idRetiro) {
                  // Imprimir Retiro
                  const model = {
                    id: success.idRetiro
                  };
                  this._comodatoService.imprimirRetiro(model).subscribe(
                      (data: any ) => {
                        const fileblob = new Blob([data], {type: 'application/pdf'});
                        const fileURL = URL.createObjectURL(fileblob);
                            window.open(fileURL);
                        // if (navigator.appVersion.toString().indexOf('.NET') > 0) {
                        //     window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
                        // } else {
                            
                        // }
                      },
                      (error) => {
                        this.toastr.error(error.message, 'Error!', {
                          timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
                        });
                      }
                    );

                }

                this.toastr.success('Devolucion de comodatos a sido guardado con exito.', 'Guardado!', {
                  timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
                });
                FormData.resetForm();
                this.modalDevRef.hide();
                this.router.navigate(['/comodatomulti']);
                this.cliente = null;
                this.remisiones = [];
              },
              error => {
                this.toastr.error(error.message, 'Error!', {
                  timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
                });
              });
        }
      });
    }
  }

  onCerrarDevolucion() {
    this.modalDevRef.hide();
    this.router.navigate(['/comodatomulti']);
    this.cliente = null;
    this.remisiones = [];
  }

  onShowProcesarRetiroVenta(template: TemplateRef<any>) {
    this.listVentas = [];
    this.listRetiros = [];

    this.pageRetiroActual = 1;
    this.pageVentaActual = 1;
    this._comodatoService.getRetirosVentas(this.cliente)
    .subscribe(
      data => {
        this.listRetiros = data.retiros;
        this.listVentas = data.ventas;
        this.modalDevRetProcRef = this.modalService.show(template, this.configLarge);
      },
      error => this.toastr.error(error.message, 'Error!', {
        timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
      }) );
  }

  onLoadRetirosVentas(){
    this._comodatoService.getRetirosVentas(this.cliente)
    .subscribe(
      data => {
        this.listRetiros = data.retiros;
        this.listVentas = data.ventas;
      },
      error => this.toastr.error(error.message, 'Error!', {
        timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
      }) );
  }

  onCerrarProcesarVentaDevolucion() {
    this.modalDevRetProcRef.hide();
  }

  async ProcesarRetiro(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea procesar retiro, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Procesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id
        };

        this._retiroService.procesar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesado!',
              text: 'Retiro procesado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onLoadRetirosVentas();
          },
          error => {
            this.toastr.error(error.message, 'Error!', {
              timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
            });
          });
      }
    });
  }

  async ProcesarVenta(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que desea procesar la venta, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Procesar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id
        };

        this._ventaService.procesar(modelAut)
        .subscribe(
          success => {
            Swal.fire({
              title: 'Procesada!',
              text: 'Venta procesada con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
            this.onLoadRetirosVentas();
          },
          error => {
            this.toastr.error(error.message, 'Error!', {
              timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
            });
          });
      }
    });
  }

  onChangePageVenta(page: any){
    this.pageVentaActual = page;
  }

  onChangePageRetiro(page: any){
    this.pageRetiroActual = page;
  }
}



