export class SolicitudAlmacenDetalle {
  ID: number;
  NoRenglon: number;
  InfoProducto: string;
  Cantidad: number;
  ExistenciaActual: number;
  CantidadSurtir: number;
  Status: string;
  ID_SolicitudAlmacen: number;
  ID_Producto?: number;
  esKit: string;
  ID_ModeloProducto?: number;
  constructor() {
    this.Cantidad = 0;
    this.NoRenglon = 0;
    this.InfoProducto = '';
    this.ExistenciaActual = 0;
    this.CantidadSurtir = 0;
    this.Status = 'A';
  }
}
