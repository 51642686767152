export class CotizacionDetalle {
  ID: number;
  NoRenglon: number;
  InfoProducto: string;
  Cantidad: number;
  Subtotal: number;
  Descuento: number;
  PrecioUnitario: number;
  Status: string;
  ID_Cotizacion: number;
  ID_Producto?: number;
  Extendido: string;
  esKit: string;
  ID_ModeloProducto?: number;
  tipoDescripcion: string;
  constructor() {
    this.Cantidad = 0;
    this.Subtotal = 0;
    this.PrecioUnitario = 0;
  }
}
