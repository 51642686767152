export class Almacen {
  ID: number;
  Nombre: string;
  Prefijo: string;
  Status: string;
  ID_Sucursal: number;
  disabled: boolean;
  constructor() {
    this.disabled = false;
  }
}
