import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { ExistenciasService } from '../../services/existencias/existencias.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  model: any;
  constructor(private _userService: UsersService, private _existenciaService: ExistenciasService) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.getDashboard();
  }

  getDashboard() {
    this._existenciaService.getDashboard()
      .subscribe(
        data => {
          this.model = data;
        },
        error => { });
  }
}
