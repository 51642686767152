export class Modelo {
  ID: number;
  Nombre: string;
  Clave: string;
  Extension: string;
  RutaImagen: string;
  PrecioBase: number;
  Status: string;
  ID_Linea_Proveedor: number;
}
