export class Proveedor {
  ID: number;
  Clave: string;
  NombreComercial: string;
  RazonSocial: string;
  ContactoVenta: string;
  ContactoCredito: string;
  Rfc: string;
  Direccion: string;
  Referencia: string;
  CodigoPostal: string;
  Colonia: string;
  Ciudad: string;
  Municipio: string;
  Estado: string;
  Pais: string;
  StatusCredito?: string;
  DiasCredito?: number;
  LimiteCredito: number;
  Descuento?: number;
  NumeroExt: string;
  NumeroInt: string;
  Status: string;
  Observaciones: string;
  Telefono: string;
  PaginaWeb: string;
  CorreoVentas: string;
  CorreoCredito: string;
}
