export class TraspasoDetalle {
  ID: number;
  Cantidad: number;
  Costo: number;
  Importe: number;
  ID_Traspaso: number;
  ID_Producto: number;
  esKit: string;
  constructor() {
    this.Cantidad = 0;
    this.Importe = 0;
  }
}
