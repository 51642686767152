import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Traspaso {
  ID: number;
  FechaEnvio: any;
  FechaRecibo?: any;
  Observaciones: string;
  Status: string;
  ID_EntradaAuto?: number;
  ID_SalidaAuto?: number;
  ID_AlmacenEnvia: number;
  ID_AlmacenRecibe: number;
  ID_TipoSalida: number;
  ID_UsuarioEnvia: number;
  ID_UsuarioRecibe: number;
  FolioAzul: number;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Observaciones = '';
    this.FechaEnvio = new Date();
    this.Status = 'G';
    this.SelectedDate = {
        day: this.FechaEnvio.getDate(),
        month: this.FechaEnvio.getMonth() + 1,
        year: this.FechaEnvio.getFullYear() };
  }
}
