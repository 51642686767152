import { Component, OnInit, ViewChild, TemplateRef, ContentChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { I18n, CustomDatepickerI18n } from '../../directives/custom-datepickerI18n';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from '../../services/users/users.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DatePipe } from '@angular/common';
import { ReportesService } from '../../services/reportes/reportes.service';
import { ImprimirDto } from '../../models/ImprimirDto';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css'],
  providers: [I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n}]
})
export class ReportesComponent implements OnInit {

  model: ImprimirDto = new ImprimirDto();
  tipo: string;
  Filter: any;
  titulo: string;
  from: NgbDateStruct;
  to: NgbDateStruct;

  sucursales: any[] = [];
  almacenesorigen: any[] = [];
  almacenesdestino: any[] = [];
  almacenes: any[] = [];
  productos: any[] = [];
  clientes: any[] = [];
  vendedores: any[] = [];
  lineas: any[] = [];
  sublineas: any[] = [];
  proveedores: any[] = [];
  tiposEntrada: any[] = [];
  tiposSalida: any[] = [];
  StatusList: any[] = [];

  StatusCotizacion = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Aceptada', Status: 'A' }, { ID: 2, Name: 'Vigente', Status: 'V' }, { ID: 3, Name: 'Expirada', Status: 'E' }, { ID: 4, Name: 'Cancelada', Status: 'C' }];
  StatusSolicitudAlmacen = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Activa', Status: 'M' }, { ID: 2, Name: 'Atendida', Status: 'A' }, { ID: 3, Name: 'Parcial', Status: 'P' }, { ID: 4, Name: 'Cancelada', Status: 'C' }];
  StatusOrdenCompra = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Activa sin autorizar', Status: 'S' }, { ID: 2, Name: 'Activa autorizada', Status: 'A' }, { ID: 3, Name: 'Surtida parcialmente', Status: 'P' }, { ID: 4, Name: 'Surtida / Cerrada', Status: 'C' }, { ID: 5, Name: 'Cancelada', Status: 'X' }];
  StatusCompra = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Procesado', Status: 'P' }, { ID: 2, Name: 'Guardado', Status: 'G' }, { ID: 3, Name: 'Cancelada', Status: 'C' }];
  StatusEntrada = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Procesado', Status: 'P' }, { ID: 2, Name: 'Guardado', Status: 'G' }, { ID: 3, Name: 'Cancelada', Status: 'C' }];
  StatusSalida = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Procesado', Status: 'P' }, { ID: 2, Name: 'Guardado', Status: 'G' }, { ID: 3, Name: 'Cancelada', Status: 'C' }];
  StatusTraspaso = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Enviado', Status: 'E' }, { ID: 2, Name: 'Guardado', Status: 'G' }, { ID: 3, Name: 'Recibido', Status: 'R' }, { ID: 4, Name: 'Transito', Status: 'T' }, { ID: 5, Name: 'Cancelado', Status: 'C' }];
  StatusVenta = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Guardado', Status: 'G' }, { ID: 2, Name: 'Cancelada', Status: 'C' }];
  StatusConsignacion = [{ ID: -1, Name: 'TODOS', Status: '' }, { ID: 1, Name: 'Activa', Status: 'A' }, { ID: 2, Name: 'Completada', Status: 'G' }, { ID: 3, Name: 'Cancelada', Status: 'C' }];

   cot = { id: true, dat: true, suc: true, almo: false, alme: false, alm: false, prod: true, cli: true, ven: true, lin: false, sublin: false, est: true, prov: false, ent: false, sal: false };
   sol = { id: true, dat: true, suc: true, almo: false, alme: false, alm: true, prod: true, cli: true, ven: true, lin: false, sublin: false, est: true, prov: false, ent: false, sal: false };
   ord = { id: true, dat: true, suc: false, almo: false, alme: false, alm: false, prod: true, cli: false, ven: false, lin: true, sublin: true, est: true, prov: true, ent: false, sal: false };
   comp = { id: true, dat: true, suc: false, almo: false, alme: false, alm: true, prod: true, cli: false, ven: false, lin: true, sublin: true, est: true, prov: true, ent: false, sal: false };
   ent = { id: true, dat: true, suc: true, almo: false, alme: false, alm: true, prod: true, cli: false, ven: false, lin: true, sublin: true, est: true, prov: true, ent: true, sal: false };
   sal = { id: true, dat: true, suc: true, almo: false, alme: false, alm: true, prod: true, cli: false, ven: false, lin: false, sublin: false, est: true, prov: true, ent: false, sal: true };
   tras = { id: true, dat: true, suc: true, almo: true, alme: true, alm: false, prod: true, cli: false, ven: false, lin: true, sublin: true, est: true, prov: false, ent: false, sal: false };
   ven = { id: true, dat: true, suc: true, almo: false, alme: false, alm: false, prod: true, cli: true, ven: true, lin: true, sublin: true, est: true, prov: false, ent: false, sal: false };
   comod = { id: true, dat: true, suc: true, almo: false, alme: false, alm: false, prod: true, cli: true, ven: true, lin: false, sublin: false, est: true, prov: false, ent: false, sal: false };
   kar = { id: false, dat: true, suc: false, almo: false, alme: false, alm: true, prod: true, cli: false, ven: false, lin: false, sublin: false, est: false, prov: false, ent: false, sal: false };
   ret = { id: true, dat: true, suc: true, almo: false, alme: false, alm: true, prod: true, cli: true, ven: false, lin: false, sublin: false, est: true, prov: false, ent: false, sal: false };
   exi = { id: false, dat: false, suc: false, almo: false, alme: false, alm: true, prod: true, cli: false, ven: false, lin: true, sublin: true, est: false, prov: false, ent: false, sal: false, negativo: true };
   dvent = { id: false, dat: true, suc: false, almo: false, alme: false, alm: true, prod: false, cli: true, ven: false, lin: false, sublin: false, est: false, prov: false, ent: false, sal: false };

  constructor(private _userService: UsersService, private _reporteService: ReportesService, private modalService: BsModalService, private toastr: ToastrService, public router: Router, private route: ActivatedRoute) {
    this._userService.loadStorage();
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
   };
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.tipo = params.tipo;
    });
    this.getCombos();
    this.load();
    this.model.Tipo = this.tipo;
  }

  getCombos() {
    this._reporteService.getCombos()
      .subscribe(
        data => {
          this.sucursales = data.sucursales;
          this.almacenesorigen = data.almacenesorigen;
          this.almacenesdestino = data.almacenesdestino;
          this.almacenes = data.almacenes;
          this.productos = data.productos;
          this.clientes = data.clientes;
          this.vendedores = data.vendedores;
          this.lineas = data.lineas;
          this.sublineas = data.sublineas;
          this.proveedores = data.proveedores;
          this.tiposEntrada = data.tiposEntrada;
          this.tiposSalida = data.tiposSalida;
        },
        error => this.toastr.error(error.message, 'Error!') );
  }

  load() {
      if (this.tipo === 'cotizacion') {
         this.StatusList = this.StatusCotizacion;
         this.titulo = 'Cotizaciones';
         this.Filter = this.cot;
      } else if (this.tipo === 'solicitud') {
         this.StatusList = this.StatusSolicitudAlmacen;
         this.titulo = 'Solicitud de Almacen';
         this.Filter = this.sol;
      } else if (this.tipo === 'orden') {
         this.StatusList = this.StatusOrdenCompra;
         this.titulo = 'Ordenes de Compra';
         this.Filter = this.ord;
      } else if (this.tipo === 'compra') {
         this.StatusList = this.StatusCompra;
         this.titulo = 'Compras';
         this.Filter = this.comp;
      } else if (this.tipo === 'entrada') {
         this.StatusList = this.StatusEntrada;
         this.titulo = 'Entradas';
         this.Filter = this.ent;
      } else if (this.tipo === 'salida') {
         this.StatusList = this.StatusSalida;
         this.titulo = 'Salidas';
         this.Filter = this.sal;
      } else if (this.tipo === 'traspaso') {
         this.StatusList = this.StatusTraspaso;
         this.titulo = 'Traspasos';
         this.Filter = this.tras;
      } else if (this.tipo === 'venta') {
         this.StatusList = this.StatusVenta;
         this.titulo = 'Ventas';
         this.Filter = this.ven;
      } else if (this.tipo === 'consignacion') {
         this.StatusList = this.StatusConsignacion;
         this.titulo = 'Comodatos';
         this.Filter = this.comod;
      } else if (this.tipo === 'comodatoactivo') {
         this.StatusList = this.StatusConsignacion;
         this.titulo = 'Comodatos Activos';
         this.Filter = this.comod;
      } else if (this.tipo === 'kardex') {
         this.titulo = 'Kardex';
         this.Filter = this.kar;
      } else if (this.tipo === 'kardexConcentrado') {
         this.titulo = 'Kardex Concentrado';
         this.Filter = this.kar;
      } else if (this.tipo === 'retiros') {
         this.titulo = 'Retiros';
         this.Filter = this.ret;
      } else if (this.tipo === 'existenciaConcentrado') {
         this.titulo = 'Existencia Concentrado';
         this.Filter = this.exi;
      } else if (this.tipo === 'diarioventas') {
         this.titulo = 'Diario Ventas';
         this.Filter = this.dvent;
      }
  }

  onImprimir(id: number) {
    this._reporteService.imprimir(this.model).subscribe(
        (data: any ) => {
          const fileblob = new Blob([data], {type: 'application/pdf'});
          if (navigator.appVersion.toString().indexOf('.NET') > 0) {
              window.navigator.msSaveOrOpenBlob(fileblob, 'CoverSheet.pdf');
          } else {
              const fileURL = URL.createObjectURL(fileblob);
              window.open(fileURL);
          }
        },
        (error) => {
          this.toastr.error(error.message, 'Error!');
        }
      );
  }

  onFechaFromChanged(selectedDate: NgbDateStruct) {
    this.model.From = this.getDateStruct(selectedDate);
  }

  onFechaToChanged(selectedDate: NgbDateStruct) {
    this.model.To = this.getDateStruct(selectedDate);
  }

  getDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
    item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
    (item.Codigo + ' - ' + item.Nombre).toLocaleLowerCase().indexOf(term) > -1;
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Producto = 'Todos';
      return;
    }

    this.model.Producto = selectedValue.Nombre;
  }

  onSucursalChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Sucursal = 'Todos';
      return;
    }

    this.model.Sucursal = selectedValue.Nombre;
  }

  onAlmacenOChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.AlmacenO = 'Todos';
      return;
    }

    this.model.AlmacenO = selectedValue.Nombre;
  }

  onAlmacenDChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.AlmacenD = 'Todos';
      return;
    }

    this.model.AlmacenD = selectedValue.Nombre;
  }

  onAlmacenChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Almacen = 'Todos';
      return;
    }

    this.model.Almacen = selectedValue.Nombre;
  }

  onClienteChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Cliente = 'Todos';
      return;
    }

    this.model.Cliente = selectedValue.Nombre;
  }

  onVendedorChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Vendedor = 'Todos';
      return;
    }

    this.model.Vendedor = selectedValue.Nombre;
  }

  onLineaChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Linea = 'Todos';
      return;
    }

    this.model.Linea = selectedValue.Nombre;
  }

  onSublineaChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.SubLinea = 'Todos';
      return;
    }

    this.model.SubLinea = selectedValue.Nombre;
  }

  onProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.Proveedor = 'Todos';
      return;
    }

    this.model.Proveedor = selectedValue.Nombre;
  }

  onTipoEChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.TipoE = 'Todos';
      return;
    }

    this.model.TipoE = selectedValue.Descripcion;
  }

  onTipoSChanged(selectedValue: any) {
    if (!selectedValue) {
      this.model.TipoS = 'Todos';
      return;
    }

    this.model.TipoS = selectedValue.Descripcion;
  }
}
