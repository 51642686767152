import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Cotizacion {
  ID: number;
  Fecha: any;
  Descripcion: string;
  Nombre: string;
  Status: string;
  CantidadArticulos: number;
  Subtotal: number;
  Descuento: number;
  Total: number;
  ID_Vendedor: number;
  ID_Cliente: number;
  ID_UsuarioModificador: number;
  ID_Sucursal: number;
  FolioCotizacion: string;
  Pie: string;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Fecha = new Date();
    this.Nombre = '';
    this.Descripcion = '';
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
