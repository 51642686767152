import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import {
  I18n,
  CustomDatepickerI18n,
} from "../../directives/custom-datepickerI18n";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users/users.service";
import { CompraProducto } from "src/app/models/CompraProducto";
import { CompraProductoDetalle } from "src/app/models/CompraProductoDetalle";
import { CompraService } from "src/app/services/service.index";
import { AclService } from "ng2-acl/dist";

@Component({
  selector: "app-compras",
  templateUrl: "./compras.component.html",
  styleUrls: ["./compras.component.css"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class ComprasComponent implements OnInit {
  @ViewChild("editModal") editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  lista: any[] = [];
  model: CompraProducto = new CompraProducto();
  modeldetalle: CompraProductoDetalle = new CompraProductoDetalle();
  detalles: CompraProductoDetalle[] = [];
  proveedorSelected = {
    Direccion: "",
    Colonia: "",
    Ciudad: "",
    Telefono: "",
    Rfc: "",
  };
  proveedorID: number;

  from: NgbDateStruct;
  to: NgbDateStruct;
  proveedor: any;
  sucursal: any;
  almacenid: any;
  folio = "";
  imr = "";
  factura = "";
  proveedores: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  productos: any[] = [];
  productosNormal: any[] = [];
  showguardar = true;
  iva: number;
  total: number;
  descuento: number;

  isEdit = false;
  currentIndexDetailRem = -1;

  constructor(
    private _userService: UsersService,
    private _compraService: CompraService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public aclService: AclService
  ) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = "";
    let to = "";
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._compraService
      .getLista(
        from,
        to,
        this.sucursal,
        this.almacenid,
        this.proveedor,
        this.imr,
        this.factura,
        this.folio
      )
      .subscribe(
        (data: any) => {
          this.lista = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que quiere guardar Compra.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.model.UsuarioUltimo = this._userService.user.ID;
          if (
            this.model.Status === undefined ||
            this.model.Status === null ||
            this.model.Status === ""
          ) {
            this.model.Status = "G";
          }

          if (
            this.model.UsuarioRegistro === undefined ||
            this.model.UsuarioRegistro == null ||
            this.model.UsuarioRegistro === 0
          ) {
            this.model.UsuarioRegistro = this._userService.user.ID;
          }

          const model = {
            model: this.model,
            detalles: this.detalles,
          };
          this._compraService.guardar(model).subscribe(
            (success) => {
              this.toastr.success("Compra guardado con exito.", "Guardado!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
              this.onBuscar();
              FormData.resetForm();
              this.modalRef.hide();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new CompraProducto();
    this.detalles = [];
    this.showguardar = true;
    this.isEdit = false;
    this.currentIndexDetailRem = -1;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._compraService.getCompra(id).subscribe(
        (data) => {
          this.model = data.model;
          this.detalles = data.detalles;
          if (this.model.FechaFactura) {
            this.model.FechaFactura = new Date(this.model.FechaFactura);
            this.model.SelectedDate = this.getDateStructFromDate(
              this.model.FechaFactura
            );
          }

          if (this.model.Status === "C") {
            this.showguardar = false;
          }

          this.proveedorSelected = this.proveedores.filter(
            (item) => item.ID === this.model.ID_Proveedor
          )[0];

          this.modalRef = this.modalService.show(template, this.configLarge);
        },
        (error) =>
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          })
      );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.FechaFactura = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._compraService.getCombos().subscribe(
      (data) => {
        this.proveedores = data.proveedores;
        this.productos = data.productos;
        this.sucursales = data.sucursales;
        this.almacenes = data.almacenes;
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onShowSolDetalle(
    model: CompraProductoDetalle,
    currentIndexDetailRem: number,
    template: TemplateRef<any>
  ) {
    this.modeldetalle = new CompraProductoDetalle();
    this.isEdit = false;
    this.currentIndexDetailRem = -1;
    if (model) {
      this.modeldetalle = <CompraProductoDetalle>(
        JSON.parse(JSON.stringify(model))
      );
      this.currentIndexDetailRem = currentIndexDetailRem;
      this.isEdit = true;
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: CompraProductoDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex(
          (Item: CompraProductoDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.isEdit == true) {
        if (this.model.Descuento > 0) {
          const total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
          this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario -
            this.model.Descuento;
        } else {
          this.modeldetalle.Descuento = 0;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
        }

        this.detalles[this.currentIndexDetailRem] = <CompraProductoDetalle>(
          JSON.parse(JSON.stringify(this.modeldetalle))
        );

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        if (this.model.Descuento > 0) {
          const total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
          this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario -
            this.model.Descuento;
        } else {
          this.modeldetalle.Descuento = 0;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
        }
        this.detalles.push(this.modeldetalle);
        this.toastr.success("Producto agregado con exito.", "Agregado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      }
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.CostoUnitario = selectedValue.Costo;
    this.modeldetalle.Descuento = 0;

    this.OnUpdateTotal();
  }

  onGetClave(model: CompraProductoDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id,
    };
    this._compraService.imprimir(model).subscribe(
      (data: any) => {
        const fileblob = new Blob([data], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, "CoverSheet.pdf");
        } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        });
      }
    );
  }

  async onProcesar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea procesar la compra, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Procesar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._compraService.procesar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Procesada!",
              text: "Compra procesada con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onCancelar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea cancelar la compra, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._compraService.cancelar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Cancelada!",
              text: "Compra cancelada con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onEliminar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea eliminar la compra, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._compraService.eliminar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Eliminada!",
              text: "Compra eliminar con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    if (this.detalles.length > 0 && this.proveedorID !== selectedValue.ID) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que desea cambiar de proveedor se eliminaran los detalles de la orden!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cambiar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.value) {
          this.proveedorSelected = JSON.parse(
            JSON.stringify(selectedValue)
          ) as any;
          this.proveedorID = selectedValue.ID;
          this.productosNormal = this.productos.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
        } else {
          this.model.ID_Proveedor = this.proveedorID;
          this.productosNormal = this.productos.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
        }
      });
    } else {
      this.proveedorSelected = JSON.parse(JSON.stringify(selectedValue)) as any;
      this.proveedorID = selectedValue.ID;
      this.productosNormal = this.productos.filter(
        (item) => item.ID_Proveedor === this.proveedorID
      );
    }
  }

  onGetSubTotal() {
    let subtotal = 0;
    let descuento = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.CostoUnitario;
      descuento += element.Descuento;
    }
    subtotal = subtotal - descuento;
    this.descuento = descuento;
    this.iva = subtotal * 0.16;
    this.total = subtotal + this.iva;
    this.model.Subtotal = subtotal;
    this.model.Total = this.total;

    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Subtotal =
      this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
    if (this.model.Descuento > 0) {
      const total =
        this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
      this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
      this.modeldetalle.Total =
        this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario -
        this.model.Descuento;
    } else {
      this.modeldetalle.Descuento = 0;
      this.modeldetalle.Total =
        this.modeldetalle.Cantidad * this.modeldetalle.CostoUnitario;
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
      item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.Codigo + " - " + item.Nombre).toLocaleLowerCase().indexOf(term) > -1
    );
  }
}
