import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import {
  I18n,
  CustomDatepickerI18n,
} from "../../directives/custom-datepickerI18n";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users/users.service";
import { TraspasosService } from "../../services/traspasos/traspasos.service";
import { Traspaso } from "../../models/Traspaso";
import { TraspasoDetalle } from "../../models/TraspasoDetalle";
import { Almacen } from "../../models/Almacen";
import { AclService } from "ng2-acl/dist";

@Component({
  selector: "app-traspasos",
  templateUrl: "./traspasos.component.html",
  styleUrls: ["./traspasos.component.css"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class TraspasosComponent implements OnInit {
  @ViewChild("editModal") editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  lista: any[] = [];
  model: Traspaso = new Traspaso();
  modeldetalle: TraspasoDetalle = new TraspasoDetalle();
  detalles: TraspasoDetalle[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  almacenorigen: any;
  almacendestino: any;
  status = "";
  folio = "";
  folioazul = "";
  almacenesorigen: Almacen[] = [];
  almacenesdestino: Almacen[] = [];
  productos: any[] = [];
  productosNormal: any[] = [];
  productoskit: any[] = [];
  productoskitNormal: any[] = [];
  showguardar = false;
  radioProducto: any;
  activeNormal: any;
  activeModelo: any;
  activeKit: any;
  existencia: number;
  iva: number;
  total: number;
  descuento: number;
  StatusList = [
    { ID: -1, Descripcion: "Todos", Status: "" },
    { ID: 1, Descripcion: "Enviado", Status: "E" },
    { ID: 2, Descripcion: "Guardado", Status: "G" },
    { ID: 3, Descripcion: "Recibido", Status: "R" },
    { ID: 4, Descripcion: "Transito", Status: "T" },
    { ID: 5, Descripcion: "Cancelado", Status: "C" },
  ];

  isEdit = false;
  currentIndexDetail = -1;
  constructor(
    private _userService: UsersService,
    private _traspasoService: TraspasosService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public aclService: AclService
  ) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = "";
    let to = "";
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    if (status === "Todos") {
      status = null;
    }

    this._traspasoService
      .getLista(
        from,
        to,
        this.almacenorigen,
        this.almacendestino,
        this.status,
        this.folioazul,
        this.folio
      )
      .subscribe(
        (data: any) => {
          this.lista = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que quiere guardar Traspaso.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          if (
            this.model.Status === undefined ||
            this.model.Status === null ||
            this.model.Status === ""
          ) {
            this.model.Status = "G";
          }

          this.model.ID_UsuarioEnvia = this._userService.user.ID;

          if (
            this.model.ID_UsuarioRecibe === undefined ||
            this.model.ID_UsuarioRecibe == null ||
            this.model.ID_UsuarioRecibe === 0
          ) {
            this.model.ID_UsuarioRecibe = this._userService.user.ID;
          }

          const model = {
            model: this.model,
            detalles: this.detalles,
          };
          this._traspasoService.guardar(model).subscribe(
            (success) => {
              this.toastr.success("Traspaso guardado con exito.", "Guardado!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
              this.onBuscar();
              FormData.resetForm();
              this.modalRef.hide();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Traspaso();
    this.detalles = [];
    this.showguardar = true;
    this.isEdit = false;
    this.currentIndexDetail = -1;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._traspasoService.getTraspaso(id).subscribe(
        (data) => {
          this.model = data.model;
          this.detalles = data.detalles;
          if (this.model.FechaEnvio) {
            this.model.FechaEnvio = new Date(this.model.FechaEnvio);
            this.model.SelectedDate = this.getDateStructFromDate(
              this.model.FechaEnvio
            );
          }

          if (this.model.Status === "G" || this.model.Status === "E") {
            this.showguardar = true;
          }

          this.modalRef = this.modalService.show(template, this.configLarge);
        },
        (error) =>
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          })
      );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.FechaEnvio = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._traspasoService.getCombos().subscribe(
      (data) => {
        this.productos = data.productos;
        this.productoskit = data.productoskit;
        this.almacenesorigen = data.almacenesorigen;
        this.almacenesdestino = data.almacenesdestino;
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onShowSolDetalle(
    model: TraspasoDetalle,
    currentIndexDetail: number,
    template: TemplateRef<any>
  ) {
    this.modeldetalle = new TraspasoDetalle();
    this.isEdit = false;
    this.currentIndexDetail = -1;
    if (model) {
      this.modeldetalle = <TraspasoDetalle>JSON.parse(JSON.stringify(model));
      this.currentIndexDetail = currentIndexDetail;
      this.isEdit = true;

      // if (model.ID_Producto && model.esKit === "N") {
      //   this.radioProducto = 1;
      // } else if (model.ID_Producto && model.esKit === "Y") {
      //   this.radioProducto = 3;
      // } else {
      //   this.radioProducto = 2;
      // }
      this.radioProducto = 1;
      this.onRadioProdChanged();
    } else {
      this.radioProducto = 1;
      this.onRadioProdChanged();
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onRadioProdChanged() {
    if (this.radioProducto === 1) {
      this.activeNormal = true;
      this.activeModelo = false;
      this.activeKit = false;
      this.modeldetalle.esKit = "N";
    } else if (this.radioProducto === 2) {
      this.activeNormal = false;
      this.activeModelo = true;
      this.activeKit = false;
      this.existencia = 0;
      this.modeldetalle.esKit = "N";
    } else {
      this.activeNormal = false;
      this.activeModelo = false;
      this.activeKit = true;
      this.modeldetalle.esKit = "Y";
    }
  }

  onDeleteSolDetalle(model: TraspasoDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex(
          (Item: TraspasoDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.isEdit == true) {
        this.modeldetalle.Importe =
          this.modeldetalle.Cantidad * this.modeldetalle.Costo;
        this.detalles[this.currentIndexDetail] = <TraspasoDetalle>(
          JSON.parse(JSON.stringify(this.modeldetalle))
        );

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        if (this.modeldetalle.esKit === "Y") {
          this._traspasoService
            .getProductos(this.modeldetalle.ID_Producto)
            .subscribe(
              (data) => {
                const kits = data.kit;
                kits.forEach((prod) => {
                  let modeldetalle_tmp = new TraspasoDetalle();
                  const producto = this.productos.filter(
                    (item) => item.ID === prod.IDProductoDetalle
                  )[0];
                  modeldetalle_tmp.Cantidad =
                    this.modeldetalle.Cantidad * prod.Cantidad;
                  modeldetalle_tmp.Costo = producto.Costo;
                  modeldetalle_tmp.Importe =
                    modeldetalle_tmp.Cantidad * modeldetalle_tmp.Costo;
                  modeldetalle_tmp.ID_Producto = prod.IDProductoDetalle;
                  this.detalles.push(modeldetalle_tmp);
                });
                this.toastr.success(
                  "Producto agregado con exito.",
                  "Agregado!",
                  {
                    timeOut: 7000,
                    progressBar: true,
                    progressAnimation: "increasing",
                  }
                );
                this.modaldetalleRef.hide();
              },
              (error) =>
                this.toastr.error(error.message, "Error!", {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "increasing",
                })
            );
        } else {
          this.modeldetalle.Importe =
            this.modeldetalle.Cantidad * this.modeldetalle.Costo;

          this.detalles.push(this.modeldetalle);
          this.toastr.success("Producto agregado con exito.", "Agregado!", {
            timeOut: 7000,
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.modaldetalleRef.hide();
        }
      }
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.esKit = selectedValue.esKit;
    this.modeldetalle.Costo = selectedValue.Costo;

    this.OnUpdateTotal();
  }

  onProductoKitChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.esKit = selectedValue.esKit;
    this.modeldetalle.Costo = selectedValue.Costo;
  }

  onGetClave(model: TraspasoDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id,
    };
    this._traspasoService.imprimir(model).subscribe(
      (data: any) => {
        const fileblob = new Blob([data], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, "CoverSheet.pdf");
        } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        });
      }
    );
  }

  async onEnviar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea enviar traspaso, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const model = {
          id,
        };

        this._traspasoService.enviar(model).subscribe(
          (success) => {
            Swal.fire({
              title: "Enviada!",
              text: "Traspaso ha sido enviado con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  async onRecibir(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea recibir traspaso, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const model = {
          id,
        };

        this._traspasoService.recibir(model).subscribe(
          (success) => {
            Swal.fire({
              title: "Cancelada!",
              text: "Traspaso ha sido recibido con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onCancelar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea cancelar traspaso, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const model = {
          id,
        };

        this._traspasoService.cancelar(model).subscribe(
          (success) => {
            Swal.fire({
              title: "Cancelada!",
              text: "Traspaso ha sido cancelado con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onEliminar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea eliminar la traspaso, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._traspasoService.eliminar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Eliminada!",
              text: "Traspaso eliminar con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onGetInfoProducto(model: TraspasoDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Nombre;
  }

  OnUpdateTotal() {
    this.modeldetalle.Importe =
      this.modeldetalle.Cantidad * this.modeldetalle.Costo;
  }

  onAlmacenOrigenChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    const p1 = this.almacenesdestino.find((p) => p.ID === selectedValue.ID);
    p1.disabled = true;
  }

  onAlmacenDestinoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    const p1 = this.almacenesorigen.find((p) => p.ID === selectedValue.ID);
    p1.disabled = true;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
      item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.Codigo + " - " + item.Nombre).toLocaleLowerCase().indexOf(term) > -1
    );
  }
}
