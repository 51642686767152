import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class Entrada {
  ID: number;
  Fecha: any;
  NoFactura: string;
  Observaciones: string;
  Status: string;
  ID_Almacen: number;
  ID_Compra?: number;
  ID_Usuario: number;
  ID_TipoEntrada: number;
  FolioEntrada?: number;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.Observaciones = '';
    this.Fecha = new Date();
    this.Status = 'G';
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
