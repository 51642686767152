import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import {
  I18n,
  CustomDatepickerI18n,
} from "../../directives/custom-datepickerI18n";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users/users.service";
import { SalidaService } from "../../services/salidas/salida.service";
import { Salida } from "src/app/models/Salida";
import { SalidaDetalle } from "src/app/models/SalidaDetalle";
import { AclService } from "ng2-acl/dist";

@Component({
  selector: "app-salidas",
  templateUrl: "./salidas.component.html",
  styleUrls: ["./salidas.component.css"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class SalidasComponent implements OnInit {
  @ViewChild("editModal") editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  lista: any[] = [];
  model: Salida = new Salida();
  modeldetalle: SalidaDetalle = new SalidaDetalle();
  detalles: SalidaDetalle[] = [];

  from: NgbDateStruct;
  to: NgbDateStruct;
  tipo: any;
  almacenid: any;
  folio = "";
  folioentrada = "";

  almacenes: any[] = [];
  tipos: any[] = [];
  productos: any[] = [];
  productosNormal: any[] = [];
  showguardar = true;

  isEdit = false;
  currentIndexDetail = -1;

  constructor(
    private _userService: UsersService,
    private _salidaService: SalidaService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public aclService: AclService
  ) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = "";
    let to = "";
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._salidaService
      .getLista(
        from,
        to,
        this.tipo,
        this.almacenid,
        this.folioentrada,
        this.folio
      )
      .subscribe(
        (data: any) => {
          this.lista = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que quiere guardar Salida.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.model.ID_Usuario = this._userService.user.ID;
          if (
            this.model.Status === undefined ||
            this.model.Status === null ||
            this.model.Status === ""
          ) {
            this.model.Status = "G";
          }

          const model = {
            model: this.model,
            detalles: this.detalles,
          };
          this._salidaService.guardar(model).subscribe(
            (success) => {
              this.toastr.success("Salida guardado con exito.", "Guardado!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
              this.onBuscar();
              FormData.resetForm();
              this.modalRef.hide();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new Salida();
    this.detalles = [];
    this.showguardar = true;
    this.isEdit = false;
    this.currentIndexDetail = -1;

    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._salidaService.getSalida(id).subscribe(
        (data) => {
          this.model = data.model;
          this.detalles = data.detalles;
          if (this.model.Fecha) {
            this.model.Fecha = new Date(this.model.Fecha);
            this.model.SelectedDate = this.getDateStructFromDate(
              this.model.Fecha
            );
          }

          if (this.model.Status === "C") {
            this.showguardar = false;
          }

          this.modalRef = this.modalService.show(template, this.configLarge);
        },
        (error) =>
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          })
      );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._salidaService.getCombos().subscribe(
      (data) => {
        this.tipos = data.tipos;
        this.productos = data.productos;
        this.almacenes = data.almacenes;
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onShowSolDetalle(
    model: SalidaDetalle,
    currentIndexDetail: number,
    template: TemplateRef<any>
  ) {
    this.modeldetalle = new SalidaDetalle();
    this.isEdit = false;
    this.currentIndexDetail = -1;
    if (model) {
      this.modeldetalle = <SalidaDetalle>JSON.parse(JSON.stringify(model));
      this.currentIndexDetail = currentIndexDetail;
      this.isEdit = true;
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onDeleteSolDetalle(model: SalidaDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex(
          (Item: SalidaDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.isEdit == true) {
        this.modeldetalle.Importe =
          this.modeldetalle.Cantidad * this.modeldetalle.CostoProm;
        this.detalles[this.currentIndexDetail] = <SalidaDetalle>(
          JSON.parse(JSON.stringify(this.modeldetalle))
        );

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        this.modeldetalle.Importe =
          this.modeldetalle.Cantidad * this.modeldetalle.CostoProm;
        this.detalles.push(this.modeldetalle);
        this.toastr.success("Producto agregado con exito.", "Agregado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      }
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.CostoProm = selectedValue.Costo;

    this.OnUpdateTotal();
  }

  onGetClave(model: SalidaDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  onGetInfoProducto(model: SalidaDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Nombre;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id,
    };
    this._salidaService.imprimir(model).subscribe(
      (data: any) => {
        const fileblob = new Blob([data], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, "CoverSheet.pdf");
        } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        });
      }
    );
  }

  async onProcesar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea procesar la salida, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Procesar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._salidaService.procesar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Procesada!",
              text: "Salida procesada con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onCancelar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea cancelar la salida, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._salidaService.cancelar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Cancelada!",
              text: "Entrada cancelada con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  onEliminar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea eliminar la salida, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const modelAut = {
          id,
        };

        this._salidaService.eliminar(modelAut).subscribe(
          (success) => {
            Swal.fire({
              title: "Eliminada!",
              text: "Salida eliminar con exito.",
              type: "success",
              confirmButtonText: "Aceptar",
            });
            this.onBuscar();
          },
          (error) => {
            this.toastr.error(error.message, "Error!", {
              timeOut: 10000,
              progressBar: true,
              progressAnimation: "increasing",
            });
          }
        );
      }
    });
  }

  OnUpdateTotal() {
    this.modeldetalle.Importe =
      this.modeldetalle.Cantidad * this.modeldetalle.CostoProm;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
      item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.Codigo + " - " + item.Nombre).toLocaleLowerCase().indexOf(term) > -1
    );
  }
}
