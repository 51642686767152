export class Cuentac {
  Cuentac() {
    this.ID = 0;
  }
  ID: number;
  Nombre: string;
  Cuenta: string;
  CLABE: string;
  Digitos: string;
  Status: string;
  ID_Banco: number;
  ID_Cliente: number;
}
