import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
export class ImprimirDto {
  From: any;
  To: any;
  ID: string;
  Status: string;
  Negativos: boolean;
  IDSucursal: number;
  IDProducto: number;
  IDCliente: number;
  IDVendedor: number;
  IDLinea: number;
  IDSubLinea: number;
  IDProveedor: number;
  IDAlmacenO: number;
  IDAlmacenD: number;
  IDAlmacen: number;
  IDTipoE: number;
  IDTipoS: number;
  Detallado: boolean;
  Tipo: string;
  Sucursal: string;
  Producto: string;
  Cliente: string;
  Vendedor: string;
  Linea: string;
  SubLinea: string;
  Proveedor: string;
  AlmacenO: string;
  AlmacenD: string;
  Almacen: string;
  TipoE: string;
  TipoS: string;

  SelectedFromDate: NgbDateStruct;
  SelectedToDate: NgbDateStruct;
  constructor() {
    this.IDSucursal = -1;
    this.IDProducto = -1;
    this.IDCliente = -1;
    this.IDLinea = -1;
    this.IDSubLinea = -1;
    this.IDProveedor = -1;
    this.IDAlmacenO = -1;
    this.IDAlmacenD = -1;
    this.IDAlmacen = -1;
    this.IDTipoE = -1;
    this.IDTipoS = -1;
    this.Negativos = false;
    this.Detallado = false;
    this.Status = '';

    this.Sucursal = 'Todos';
    this.Producto = 'Todos';
    this.Cliente = 'Todos';
    this.Vendedor = 'Todos';
    this.Linea = 'Todos';
    this.SubLinea = 'Todos';
    this.Proveedor = 'Todos';
    this.AlmacenO = 'Todos';
    this.AlmacenD = 'Todos';
    this.Almacen = 'Todos';
    this.TipoE = 'Todos';
    this.TipoS = 'Todos';
  }
}
