import { RemisionDetalleDto } from './RemisionDetalleDto';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class RemisionDto {
  ID: number;
  Fecha: any;
  Status: string;
  Observaciones: string;
  ID_Cliente?: number;
  ID_Almacen: number;
  ID_Vendedor?: number;
  FolioSucursal: string;
  remisionDetalles: RemisionDetalleDto[];
  SelectedDate: NgbDateStruct;
  constructor() {
    this.remisionDetalles = [];
    this.Fecha = new Date();
    this.SelectedDate = {
      day: this.Fecha.getDate(),
      month: this.Fecha.getMonth() + 1,
      year: this.Fecha.getFullYear() };
  }
}
