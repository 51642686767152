export class Direccionc {
  Direccionc() {
    this.ID = 0;
  }

  ID: number;
  Direccion: string;
  Colonia: string;
  Referencia: string;
  Estado: string;
  CodigoPostal: string;
  Telefono: string;
  Contacto: string;
  Observaciones: string;
  esDefault: string;
  Status: string;
  ID_Cliente: number;
  Municipio: string;
}
