import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { UsersService } from '../../services/users/users.service';
import { SubLineaService } from '../../services/sublineas/sublineas.service';
import { SubLinea } from '../../models/SubLinea';
import { AclService } from 'ng2-acl/dist';

@Component({
  selector: 'app-sublineas-producto',
  templateUrl: './sublineas-producto.component.html',
  styleUrls: ['./sublineas-producto.component.css']
})
export class SublineasProductoComponent implements OnInit {
  @ViewChild('editModal') editModal: ModalDirective;
  modalRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'gray modal-lg'
  };
  public toastconfig: any = { timeOut: 0, extendedTimeOut: 0, preventDuplicates: true, maxOpened: 1, autoDismiss: false };
  lineaFilter: string = '';
  IdProveedor:number;
  sublineas: any[] = [];
  model: SubLinea = new SubLinea();
  lineas: any[] = [];

  constructor(private _userService: UsersService, private _sublineaService: SubLineaService, private modalService: BsModalService, private toastr: ToastrService, public aclService: AclService) { 
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
  }

  onBuscar() {
    this._sublineaService.getLista(this.lineaFilter).subscribe(
      (data: any) => {
        this.sublineas = data;
      },
      (error) => {
        Swal.fire({
          title: 'Error!',
          text: String(error.message),
          type: 'error',
          focusConfirm: false,
          focusCancel: false,
          allowEnterKey: false
        });
      }
    );
  }

  onSubmit(FormData) {
    if (FormData.valid) {
      this._sublineaService.guardar(this.model)
    .subscribe(
      success => {
        this.toastr.success('SubLínea de producto guardado con exito.', 'Guardado!', {
          timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
        });
        this.onBuscar();
        FormData.resetForm();
        this.modalRef.hide();
      },
      error => {
        this.toastr.error(error.message, 'Error!', {
          timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
        });
      });
    }
  }

  onDelete(id: number) {
    Swal.fire({
      title: 'Esta seguro?',
      text: 'Esta seguro que quiere eliminar SubLínea de producto, no se podra revertir!',
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false
    }).then((result) => {
      if (result.value) {
        this._sublineaService.eliminar(id)
        .subscribe(
          success => {
            this.onBuscar();
            Swal.fire({
              title: 'Eliminado!',
              text: 'SubLínea de producto a sido eliminado con exito.',
              type: 'success',
              confirmButtonText: 'Aceptar'
            });
          },
          error => {
            this.toastr.error(error.message, 'Error!', {
              timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
            });
          });
      }
    });
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new SubLinea();
    this.getLineas();
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.config);
    } else {
      this._sublineaService.getSubLinea(id)
    .subscribe(
      data => {
        this.model = data;
        this.modalRef = this.modalService.show(template, this.config);
      },
      error => this.toastr.error(error.message, 'Error!', {
        timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
      }) );
    }
  }

  getLineas() {
    this._sublineaService.getLineas()
      .subscribe(
        data => {
          this.lineas = data;
        },
        error => this.toastr.error(error.message, 'Error!', {
          timeOut: 10000, progressBar: true, progressAnimation: 'increasing'
        }) );
   }
}
