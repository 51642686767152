import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tipos-es',
  templateUrl: './tipos-es.component.html',
  styleUrls: ['./tipos-es.component.css']
})
export class TiposESComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
