import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class ProductoDescomposicion {
  ID: number;
  Fecha: any;
  Descripcion: string;
  CostoBase: number;
  Status: string;
  ID_ProductoBase: number;
  ID_Usuario: number;
  ID_Salida?: number;
  ID_Almacen: number;
  Cantidad?: number;
  CostoTotal?: number;
  Existencia?: number;
  SelectedDate: NgbDateStruct;
  constructor() {
    this.ID = 0;
    this.CostoBase = 0;
    this.Cantidad = 0;
    this.Descripcion = '';
    this.Fecha = new Date();
    this.Status = 'G';
    this.SelectedDate = {
        day: this.Fecha.getDate(),
        month: this.Fecha.getMonth() + 1,
        year: this.Fecha.getFullYear() };
  }
}
