export class Sucursal {
  ID: number;
  Nombre: string;
  Direccion: string;
  Colonia: string;
  Ciudad: string;
  Estado: string;
  CodigoPostal: string;
  Rfc: string;
  Telefono: string;
  Fax: string;
  Status: string;
  Abreviacion: string;
}
