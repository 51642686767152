export class ProductoDescomposicionDetalle {
  ID: number;
  Cantidad: number;
  Costo: number;
  ID_Descomposicion: number;
  ID_ProductoNuevo: number;
  ID_Entrada?: number;
  Total?: number;
  constructor() {
    this.Cantidad = 0;
    this.Costo = 0;
  }
}
