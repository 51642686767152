export class ProductoKit {
  public ProductoKit(){
    this.ID = 0;
    this.Fecha = Date();
    this.Observaciones = '';
    this.Status = 'A';
  }
  ID: number;
  Fecha: any;
  Cantidad: number;
  Observaciones: string;
  Status: string;
  IDProductoBase: number;
  IDProductoDetalle: number;
  Clave: string;
  Nombre: string;
}
