export class CompraProductoDetalle {
  ID: number;
  NoRenglon: number;
  InfoProducto: string;
  Observaciones: string;
  Cantidad: number;
  CostoUnitario: number;
  Subtotal: number;
  Descuento: number;
  Impuestos: number;
  Total: number;
  Status: string;
  ID_Compra: number;
  ID_Producto: number;
  CostoUnitarioDescuento?: number;
  constructor() {
    this.Cantidad = 0;
    this.NoRenglon = 0;
    this.InfoProducto = '';
    this.Status = 'A';
    this.Observaciones = 'NA';
  }
}
