import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { BsModalService } from "ngx-bootstrap/modal";
import { BsModalRef } from "ngx-bootstrap/modal/bs-modal-ref.service";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";
import {
  I18n,
  CustomDatepickerI18n,
} from "../../directives/custom-datepickerI18n";
import { NgbDatepickerI18n, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { UsersService } from "../../services/users/users.service";
import { OrdenCompra } from "../../models/OrdenCompra";
import { OrdenCompraDetalle } from "../../models/OrdenCompraDetalle";
import { OrdenCompraService } from "../../services/ordencompra/ordencompra.service";
import { AclService } from "ng2-acl/dist";

@Component({
  selector: "app-orden-compras",
  templateUrl: "./orden-compras.component.html",
  styleUrls: ["./orden-compras.component.css"],
  providers: [
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
  ],
})
export class OrdenComprasComponent implements OnInit {
  @ViewChild("editModal") editModal: ModalDirective;
  modalRef: BsModalRef;
  modaldetalleRef: BsModalRef;
  modalordenRef: BsModalRef;
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-lg",
  };
  configLarge = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "Custom-size-modal",
  };
  configMd = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: "gray modal-md",
  };

  lista: any[] = [];
  model: OrdenCompra = new OrdenCompra();
  modeldetalle: OrdenCompraDetalle = new OrdenCompraDetalle();
  detalles: OrdenCompraDetalle[] = [];
  proveedorSelected = {
    Direccion: "",
    Colonia: "",
    Ciudad: "",
    Telefono: "",
    Rfc: "",
  };
  proveedorID: number;

  from: NgbDateStruct;
  to: NgbDateStruct;
  proveedor: any;
  cliente: any;
  sucursal: any;
  almacenid: any;
  folio = "";
  proveedores: any[] = [];
  clientes: any[] = [];
  sucursales: any[] = [];
  almacenes: any[] = [];
  productos: any[] = [];
  productosNormal: any[] = [];
  productoskit: any[] = [];
  productoskitNormal: any[] = [];
  showguardar = true;
  radioProducto: any;
  activeNormal: any;
  activeModelo: any;
  activeKit: any;
  existencia: number;
  iva: number;
  total: number;
  descuento: number;
  StatusList = [
    { ID: 1, Descripcion: "Activa sin autorizar", Status: "S" },
    { ID: 2, Descripcion: "Activa autorizada", Status: "A" },
    { ID: 3, Descripcion: "Surtida parcialmente", Status: "P" },
    { ID: 4, Descripcion: "Surtida / Cerrada", Status: "C" },
    { ID: 5, Descripcion: "Cancelada", Status: "X" },
  ];

  isEdit = false;
  currentIndexDetail = -1;

  constructor(
    private _userService: UsersService,
    private _ordenService: OrdenCompraService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    public aclService: AclService
  ) {
    this._userService.loadStorage();
  }

  ngOnInit() {
    this.onBuscar();
    this.getCombos();
  }

  onBuscar() {
    let from = "";
    let to = "";
    if (this.from !== undefined && this.to !== undefined) {
      from = `${this.from.year}-${this.from.month}-${this.from.day}`;
      to = `${this.to.year}-${this.to.month}-${this.to.day}`;
    }

    this._ordenService
      .getLista(
        from,
        to,
        this.sucursal,
        this.almacenid,
        this.proveedor,
        this.folio
      )
      .subscribe(
        (data: any) => {
          this.lista = data;
        },
        (error) => {
          Swal.fire({
            title: "Error!",
            text: String(error.message),
            type: "error",
            focusConfirm: false,
            focusCancel: false,
            allowEnterKey: false,
          });
        }
      );
  }

  onSubmit(FormData: any) {
    if (FormData.valid) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que quiere guardar Orden de Compra.",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Guardar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then((result) => {
        if (result.value) {
          this.model.UltimoUsuario = this._userService.user.ID;
          if (
            this.model.Status === undefined ||
            this.model.Status === null ||
            this.model.Status === ""
          ) {
            this.model.Status = "S";
          }

          if (
            this.model.UsuarioRegistro === undefined ||
            this.model.UsuarioRegistro == null ||
            this.model.UsuarioRegistro === 0
          ) {
            this.model.UsuarioRegistro = this._userService.user.ID;
          }

          const model = {
            model: this.model,
            detalles: this.detalles,
          };
          this._ordenService.guardar(model).subscribe(
            (success) => {
              this.toastr.success(
                "Orden de Compra guardado con exito.",
                "Guardado!",
                {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "increasing",
                }
              );
              this.onBuscar();
              FormData.resetForm();
              this.modalRef.hide();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      });
    }
  }

  onShow(id: number, template: TemplateRef<any>) {
    this.model = new OrdenCompra();
    this.detalles = [];
    this.showguardar = true;
    this.isEdit = false;
    this.currentIndexDetail = -1;
    if (id <= 0) {
      this.modalRef = this.modalService.show(template, this.configLarge);
    } else {
      this._ordenService.getOrden(id).subscribe(
        (data) => {
          this.model = data.model;
          this.detalles = data.detalles;
          if (this.model.Fecha) {
            this.model.Fecha = new Date(this.model.Fecha);
            this.model.SelectedDate = this.getDateStructFromDate(
              this.model.Fecha
            );
          }

          if (this.model.Status !== "S") {
            this.showguardar = false;
          }

          this.proveedorSelected = this.proveedores.filter(
            (item) => item.ID === this.model.ID_Proveedor
          )[0];

          this.modalRef = this.modalService.show(template, this.configLarge);
        },
        (error) =>
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          })
      );
    }
  }

  onFechaSolChanged(selectedDate: NgbDateStruct) {
    this.model.Fecha = this.getDateFromDateStruct(selectedDate);
  }

  getCombos() {
    this._ordenService.getCombos().subscribe(
      (data) => {
        this.proveedores = data.proveedores;
        this.clientes = data.clientes;
        this.productos = data.productos;
        this.productoskit = data.productoskit;
        this.sucursales = data.sucursales;
        this.almacenes = data.almacenes;
      },
      (error) =>
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        })
    );
  }

  onShowSolDetalle(
    model: OrdenCompraDetalle,
    currentIndexDetail: number,
    template: TemplateRef<any>
  ) {
    this.modeldetalle = new OrdenCompraDetalle();
    this.isEdit = false;
    this.currentIndexDetail = -1;
    if (model) {
      this.modeldetalle = <OrdenCompraDetalle>JSON.parse(JSON.stringify(model));
      this.currentIndexDetail = currentIndexDetail;
      this.isEdit = true;

      if (model.ID_Producto && model.esKit === "N") {
        this.radioProducto = 1;
      } else if (model.ID_Producto && model.esKit === "Y") {
        this.radioProducto = 3;
      } else {
        this.radioProducto = 2;
      }

      this.onRadioProdChanged();
    } else {
      this.radioProducto = 1;
      this.onRadioProdChanged();
    }
    this.modaldetalleRef = this.modalService.show(template, this.configMd);
  }

  onRadioProdChanged() {
    if (this.radioProducto === 1) {
      this.activeNormal = true;
      this.activeModelo = false;
      this.activeKit = false;
      this.modeldetalle.esKit = "N";
    } else if (this.radioProducto === 2) {
      this.activeNormal = false;
      this.activeModelo = true;
      this.activeKit = false;
      this.existencia = 0;
      this.modeldetalle.esKit = "N";
    } else {
      this.activeNormal = false;
      this.activeModelo = false;
      this.activeKit = true;
      this.modeldetalle.esKit = "Y";
    }
  }

  onDeleteSolDetalle(model: OrdenCompraDetalle) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que quiere eliminar detalle, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then((result) => {
      if (result.value) {
        const Index = this.detalles.findIndex(
          (Item: OrdenCompraDetalle) => Item.ID === model.ID
        );
        if (Index !== -1) {
          this.detalles.splice(Index, 1);
        }
        Swal.fire({
          title: "Eliminado!",
          text: "Detalle a sido eliminado con exito.",
          type: "success",
          confirmButtonText: "Aceptar",
        });
      }
    });
  }

  onSubmitDetalle(FormData: any) {
    if (FormData.valid) {
      if (this.isEdit == true) {
        if (this.model.Descuento > 0) {
          const total =
            this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
          this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario -
            this.model.Descuento;
        } else {
          this.modeldetalle.Descuento = 0;
          this.modeldetalle.Total =
            this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
        }

        this.detalles[this.currentIndexDetail] = <OrdenCompraDetalle>(
          JSON.parse(JSON.stringify(this.modeldetalle))
        );

        this.toastr.success("Producto actualizado con exito.", "Actualizado!", {
          timeOut: 7000,
          progressBar: true,
          progressAnimation: "increasing",
        });
        this.modaldetalleRef.hide();
      } else {
        if (this.modeldetalle.esKit === "Y") {
          this._ordenService
            .getProductos(this.modeldetalle.ID_Producto)
            .subscribe(
              (data) => {
                const kits = data.kit;
                kits.forEach((prod) => {
                  let modeldetalle_tmp = new OrdenCompraDetalle();
                  const producto = this.productos.filter(
                    (item) => item.ID === prod.IDProductoDetalle
                  )[0];
                  modeldetalle_tmp.InfoProducto = producto.PRD_Nombre;
                  modeldetalle_tmp.Cantidad =
                    this.modeldetalle.Cantidad * prod.Cantidad;
                  modeldetalle_tmp.PrecioUnitario = producto.Costo;
                  modeldetalle_tmp.Subtotal =
                    modeldetalle_tmp.Cantidad * modeldetalle_tmp.PrecioUnitario;
                  if (this.model.Descuento > 0) {
                    const total =
                      modeldetalle_tmp.Cantidad *
                      modeldetalle_tmp.PrecioUnitario;
                    modeldetalle_tmp.Descuento =
                      (this.model.Descuento * total) / 100;
                    modeldetalle_tmp.Total =
                      modeldetalle_tmp.Cantidad *
                        modeldetalle_tmp.PrecioUnitario -
                      this.model.Descuento;
                  } else {
                    modeldetalle_tmp.Descuento = 0;
                    modeldetalle_tmp.Total =
                      modeldetalle_tmp.Cantidad *
                      modeldetalle_tmp.PrecioUnitario;
                  }

                  modeldetalle_tmp.ID_Producto = prod.IDProductoDetalle;
                  this.detalles.push(modeldetalle_tmp);
                });
                this.toastr.success(
                  "Producto agregado con exito.",
                  "Agregado!",
                  {
                    timeOut: 7000,
                    progressBar: true,
                    progressAnimation: "increasing",
                  }
                );
                this.modaldetalleRef.hide();
              },
              (error) =>
                this.toastr.error(error.message, "Error!", {
                  timeOut: 10000,
                  progressBar: true,
                  progressAnimation: "increasing",
                })
            );
        } else {
          if (this.model.Descuento > 0) {
            const total =
              this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
            this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
            this.modeldetalle.Total =
              this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario -
              this.model.Descuento;
          } else {
            this.modeldetalle.Descuento = 0;
            this.modeldetalle.Total =
              this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
          }
          this.detalles.push(this.modeldetalle);
          this.toastr.success("Producto agregado con exito.", "Agregado!", {
            timeOut: 7000,
            progressBar: true,
            progressAnimation: "increasing",
          });
          this.modaldetalleRef.hide();
        }
      }
    }
  }

  onProductoChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }

    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.PrecioUnitario = selectedValue.Costo;
    this.modeldetalle.esKit = selectedValue.esKit;
    this.modeldetalle.Descuento = 0;

    this.OnUpdateTotal();
  }

  onProductoKitChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    this.modeldetalle.InfoProducto = selectedValue.Nombre;
    this.modeldetalle.PrecioUnitario = selectedValue.Costo;
    this.modeldetalle.esKit = selectedValue.esKit;
    this.modeldetalle.Descuento = 0;
  }

  onGetClave(model: OrdenCompraDetalle) {
    const producto = this.productos.filter(
      (item) => item.ID === model.ID_Producto
    )[0];
    return producto.Codigo;
  }

  getDateFromDateStruct(date: NgbDateStruct): Date {
    return new Date(date.year, date.month - 1, date.day);
  }

  getDateStructFromDate(date: Date): NgbDateStruct {
    const dateStruct: NgbDateStruct = {
      day: date.getDate(),
      month: date.getMonth() + 1,
      year: date.getFullYear(),
    };

    return dateStruct;
  }

  onImprimir(id: number) {
    const model = {
      id,
    };
    this._ordenService.imprimir(model).subscribe(
      (data: any) => {
        const fileblob = new Blob([data], { type: "application/pdf" });
        if (navigator.appVersion.toString().indexOf(".NET") > 0) {
          window.navigator.msSaveOrOpenBlob(fileblob, "CoverSheet.pdf");
        } else {
          const fileURL = URL.createObjectURL(fileblob);
          window.open(fileURL);
        }
      },
      (error) => {
        this.toastr.error(error.message, "Error!", {
          timeOut: 10000,
          progressBar: true,
          progressAnimation: "increasing",
        });
      }
    );
  }

  async onAutorizar(id: number) {
    const { value: formValues } = await Swal.fire({
      title: "Autorización",
      html:
        'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
        'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      confirmButtonText: "Aceptar",
      preConfirm: () => {
        return [
          (document.getElementById("swal-input1") as any).value,
          (document.getElementById("swal-input2") as any).value,
        ];
      },
    });

    if (formValues) {
      const us = formValues[0];
      const pass = formValues[1];

      const modelAut = {
        usuario: us,
        password: pass,
        id,
      };

      this._ordenService.autorizar(modelAut).subscribe(
        (success) => {
          Swal.fire({
            title: "Autorizada!",
            text: "Orde de compra autorizada con exito.",
            type: "success",
            confirmButtonText: "Aceptar",
          });
          this.onBuscar();
        },
        (error) => {
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          });
        }
      );
    }
  }

  onCancelar(id: number) {
    Swal.fire({
      title: "Esta seguro?",
      text: "Esta seguro que desea cancelar la Orden de compra, no se podra revertir!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
      focusConfirm: false,
      focusCancel: false,
      allowEnterKey: false,
    }).then(async (result) => {
      if (result.value) {
        const { value: formValues } = await Swal.fire({
          title: "Autorización - Cancelar",
          html:
            'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
            'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
          focusConfirm: false,
          confirmButtonText: "Aceptar",
          preConfirm: () => {
            return [
              (document.getElementById("swal-input1") as any).value,
              (document.getElementById("swal-input2") as any).value,
            ];
          },
        });

        if (formValues) {
          const us = formValues[0];
          const pass = formValues[1];

          const modelAut = {
            usuario: us,
            password: pass,
            id,
          };

          this._ordenService.cancelar(modelAut).subscribe(
            (success) => {
              Swal.fire({
                title: "Cancelada!",
                text: "Orden de compra ha sido cancelada con exito.",
                type: "success",
                confirmButtonText: "Aceptar",
              });
              this.onBuscar();
            },
            (error) => {
              this.toastr.error(error.message, "Error!", {
                timeOut: 10000,
                progressBar: true,
                progressAnimation: "increasing",
              });
            }
          );
        }
      }
    });
  }

  async onCerrar(id: number) {
    const { value: formValues } = await Swal.fire({
      title: "Autorización - Cerrar Orden",
      html:
        'Usuario: <br> <input id="swal-input1" class="swal2-input">' +
        'Contraseña: <br> <input type="password" id="swal-input2" class="swal2-input">',
      focusConfirm: false,
      confirmButtonText: "Aceptar",
      preConfirm: () => {
        return [
          (document.getElementById("swal-input1") as any).value,
          (document.getElementById("swal-input2") as any).value,
        ];
      },
    });

    if (formValues) {
      const us = formValues[0];
      const pass = formValues[1];

      const modelAut = {
        usuario: us,
        password: pass,
        id,
      };

      this._ordenService.surtir(modelAut).subscribe(
        (success) => {
          Swal.fire({
            title: "Surtida!",
            text: "Solicitud de almacen ha sido surtida con exito.",
            type: "success",
            confirmButtonText: "Aceptar",
          });
          this.onBuscar();
        },
        (error) => {
          this.toastr.error(error.message, "Error!", {
            timeOut: 10000,
            progressBar: true,
            progressAnimation: "increasing",
          });
        }
      );
    }
  }

  onProveedorChanged(selectedValue: any) {
    if (!selectedValue) {
      return;
    }
    if (this.detalles.length > 0 && this.proveedorID !== selectedValue.ID) {
      Swal.fire({
        title: "Esta seguro?",
        text: "Esta seguro que desea cambiar de proveedor se eliminaran los detalles de la orden!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Cambiar!",
        focusConfirm: false,
        focusCancel: false,
        allowEnterKey: false,
      }).then(async (result) => {
        if (result.value) {
          this.proveedorSelected = JSON.parse(
            JSON.stringify(selectedValue)
          ) as any;
          this.proveedorID = selectedValue.ID;
          this.productosNormal = this.productos.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
          this.productoskitNormal = this.productoskit.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
        } else {
          this.model.ID_Proveedor = this.proveedorID;
          this.productosNormal = this.productos.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
          this.productoskitNormal = this.productoskit.filter(
            (item) => item.ID_Proveedor === this.proveedorID
          );
        }
      });
    } else {
      this.proveedorSelected = JSON.parse(JSON.stringify(selectedValue)) as any;
      this.proveedorID = selectedValue.ID;
      this.productosNormal = this.productos.filter(
        (item) => item.ID_Proveedor === this.proveedorID
      );
      this.productoskitNormal = this.productoskit.filter(
        (item) => item.ID_Proveedor === this.proveedorID
      );
    }
  }

  onGetSubTotal() {
    let subtotal = 0;
    let descuento = 0;
    // tslint:disable-next-line: prefer-for-of
    for (let index = 0; index < this.detalles.length; index++) {
      const element = this.detalles[index];
      subtotal += element.Cantidad * element.PrecioUnitario;
      descuento += element.Descuento;
    }
    subtotal = subtotal - descuento;
    this.descuento = descuento;
    this.iva = subtotal * 0.16;
    this.total = subtotal + this.iva;
    this.model.Subtotal = subtotal;
    this.model.Total = this.total;
    this.model.Impuestos = this.iva;
    return subtotal;
  }

  OnUpdateTotal() {
    this.modeldetalle.Subtotal =
      this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
    if (this.model.Descuento > 0) {
      const total =
        this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
      this.modeldetalle.Descuento = (this.model.Descuento * total) / 100;
      this.modeldetalle.Total =
        this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario -
        this.model.Descuento;
    } else {
      this.modeldetalle.Descuento = 0;
      this.modeldetalle.Total =
        this.modeldetalle.Cantidad * this.modeldetalle.PrecioUnitario;
    }
  }

  customSearchFn(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return (
      item.Codigo.toLocaleLowerCase().indexOf(term) > -1 ||
      item.Nombre.toLocaleLowerCase().indexOf(term) > -1 ||
      (item.Codigo + " - " + item.Nombre).toLocaleLowerCase().indexOf(term) > -1
    );
  }
}
